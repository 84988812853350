import React, { useRef, useState, useEffect } from "react";
import {
  Alert,
  Dimensions,
  FlatList,
  View,
  ScrollView,
  LogBox,
  Image,
  Text,
} from "react-native";
import PropTypes from "prop-types";
import { MenuItem, Select, ListItemIcon } from "@mui/material";

import CircleButton from "../../../../../components/CircleButton";
import ItemRow from "../../../../../components/ItemRow";
import { Box, Modal } from "@mui/material";
import { SafeAreaView } from "react-native-web";
import NavHeader from "../../../../../components/NavHeader/NavHeader";
import Swal from "sweetalert2";
import '@sweetalert2/theme-dark/dark.scss';

import { red, white, darkThemeBackground } from "../../../../../utils/color";
import { 
    CancelIcon,
    MoreIcon, 
    PlusIcon, 
    LinkIcon, 
    MerchandiseIcon, 
    CrownIcon, 
    WebIcon,
    VideoCameraIcon
 } from "../../../../../utils/icons";
import { Check } from "../../../../../utils/images";
import { isEmpty } from "../../../../../utils/text";

import style from "./style";
import TextSmallField from "../../../../../components/TextSmallField";
import ActionButton from "../../../../../components/ActionButton";

LogBox.ignoreLogs(["VirtualizedLists should never be nested"]);

const formComplete = (name, link, linkType) => !isEmpty(name) && !isEmpty(link) && (linkType !== null && linkType !== 'PLACEHOLDER_VALUE');

const handleAdd = (
  name,
  link,
  linkType,
  links,
  setLinks,
  setLinkOpen,
  setLinkType,
  onChangeName,
  onChangeLink,
  setEditing,
  setOpen,
) => {
    if (links.length >= 8) {
        Swal.fire({
            icon: 'error',
            title: 'Link Limit Reached',
            text: 'Currently we have a limit of 7 links per event.',
          })
        return;
    }
    const linkTypeExists = links.some(existingLink => existingLink.linkType === linkType);

    if (linkTypeExists) {
        Swal.fire({
            icon: 'error',
            title: 'Oops',
            text: 'Another link of that type already exists!',
          })
        return;
    }
    if (formComplete(name, link, linkType)) {
      const newLink = {
        linkName: name,
        link: link,
        linkType: linkType
      };
      setLinks(prevLinks => [...prevLinks, newLink]);
      onChangeName("");
      onChangeLink("");
      setLinkType(null);
      setEditing(false);
      setOpen(false);
      setLinkOpen(false);
    } else {
        Swal.fire({
            icon: 'error',
            title: 'Oops',
            text: 'Please enter a name, link, and link type.',
          })
    }
};


const handleDelete = (name, links, setLinks) => {
  const index = links.findIndex((link) => link.linkName === name);
    if (index != -1) {
      links.splice(index, 1);
      const updated = [...links];
      setLinks(updated);
    }
};


const handleEdit = (
    name,
    link,
    linkType,
    setLinkOpen,
    links,
    setLinks,
    currentLinkIndex,
    setCurrentLinkIndex,
    setLinkType,
    onChangeName,
    onChangeLink,
    setEditing,
    setOpen
) => {
  if (formComplete(name, link, linkType)) {
    
    const updated = [...links];
    if (currentLinkIndex !== null && updated[currentLinkIndex]) {

        const duplicateLinkTypeExists = links.some((l, index) => 
            l.linkType === linkType && index !== currentLinkIndex
        );

        if (duplicateLinkTypeExists) {
            Swal.fire({
                icon: 'error',
                title: 'Oops',
                text: 'Another link with the same type already exists!',
              })
            return;
        }
        // Update only the specific link object
        updated[currentLinkIndex] = {
          linkName: name,
          link: link,
          linkType: linkType
        };
      }
      setLinks(updated);
      setLinkOpen(false);
      setCurrentLinkIndex(null);
      onChangeName("");
      onChangeLink("");
      setLinkType(null);
      setEditing(false);
      setOpen(false);
  } else {
    Swal.fire({
        icon: 'error',
        title: 'Oops',
        text: 'Please enter a name, link, and link type.',
      })
  }
};

const renderItem = (
  item,
  index,
  setCurrentLinkIndex,
  setLinkOpen,
  links,
  setLinks,
  onChangeName,
  onChangeLink,
  setLinkType,
  setEditing
) => {
  let subtitleIcon = null;
  let subtitle;

  if (item.linkType == 'Table') {
    subtitleIcon = CrownIcon;
  } else if (item.linkType == 'Merchandise') {
    subtitleIcon = MerchandiseIcon;
  } else if (item.linkType == 'Website') {
    subtitleIcon = WebIcon;
  } else if (item.linkType == 'Video')  {
    subtitleIcon = VideoCameraIcon;
  } else if (item.linkType == 'Other')  {
    subtitleIcon = LinkIcon;
  }

  let text = `${item.link}`;
  text = text.toLowerCase();

  if (text.startsWith("https://www.")) {
    text = text.substring(12);
  } else if (text.startsWith("www.")) {
    text = text.substring(4);
  } else if (text.startsWith("https://")) {
    text = text.substring(8);
  } 

  if (text.length > 24) {
    text = text.substring(0, 24) + '...';
  }
  if (subtitleIcon !== null) {
    subtitle = {
        text: text,
        icon: subtitleIcon
    } 
  } else {
    subtitle = text;
  }
  

  const icons = [];

  icons.push(
    {
      icon: MoreIcon,
      onPress: () => {
        onChangeName(item.linkName);
        onChangeLink(item.link);
        setLinkType(item.linkType);
        setCurrentLinkIndex(index);
        setEditing(true);
        setLinkOpen(true);
      },
    },
    {
      icon: CancelIcon,
      tintColor: red,
      onPress: () => handleDelete(item.linkName, links, setLinks),
    }
  );

  return (
    <ItemRow
      theme={"dark"}
      hideImage={true}
      title={item.linkName?.length > 28 ? `${item.linkName.slice(0, 28)}...` : item.linkName}
      subtitle={subtitle}
      icons={icons}
      onPress={() => {}}
    />
  );
};

const renderHeader = (setLinkOpen) => (
  <ItemRow
    theme={"dark"}
    hideImage={true}
    title="New Link"
    icons={[
      {
        icon: PlusIcon,
        onPress: () => {
            setLinkOpen(true);
        },
      },
    ]}
    onPress={() => {
        setLinkOpen(true);
    }}
  />
);

const renderEmpty = () => <></>;


const EventLinksView = (props) => {
  const [links, setLinks] = useState(() => props.links || []);
  const [name, onChangeName] = useState("");
  const [link, onChangeLink] = useState("");
  const [editing, setEditing] = useState(false);
  const [currentLinkIndex, setCurrentLinkIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [linkType, setLinkType] = useState('PLACEHOLDER_VALUE');



  const [focused, setFocused] = useState("");
  const [linkOpen, setLinkOpen] = useState(false);
  const nameRef = useRef();
  const linkRef = useRef();
  const scrollViewRef = React.useRef();


  let timeoutId;

  const handleConfirm = () => {
    console.log("links: ", links);
    props.setLinks(links);
    props.navigate(-1)
  };

  
  const [items, setItems] = useState([
    {label: 'Tables/Bottle Service', value: 'Table', icon: () => <Image source={CrownIcon} style={{
        ...style.iconStyle,
        tintColor: white,
    }} resizeMode="contain" />},
    {label: 'Merchandise', value: 'Merchandise', icon: () => <Image source={MerchandiseIcon} style={{
        ...style.iconStyle,
        tintColor: white,
    }} resizeMode="contain" />},
    {label: 'Website', value: 'Website', icon: () => <Image source={WebIcon} style={{
        ...style.iconStyle,
        tintColor: white,
    }} resizeMode="contain" />},
    {label: 'Promo Video', value: 'Video', icon: () => <Image source={VideoCameraIcon} style={{
        ...style.iconStyle,
        tintColor: white,
    }} resizeMode="contain" />},
    {label: 'Other', value: 'Other', icon: () => <Image source={LinkIcon} style={{
        ...style.iconStyle,
        tintColor: white,
    }} resizeMode="contain" />}
]);

  return (
    <SafeAreaView style={style.screen}>
        <NavHeader
                onPress={() => {
                    props.navigate(-1);
                }}
            />
      <View style={style.container}>
        <Text style={style.header}>Manage Links</Text>
        <ScrollView style={style.linkList}>
            <FlatList
              style={style.list}
              data={links}
              renderItem={({ item, index }) =>
                renderItem(
                  item,
                  index,
                  setCurrentLinkIndex,
                  setLinkOpen,
                  links,
                  setLinks,
                  onChangeName,
                  onChangeLink,
                  setLinkType,
                  setEditing
                )
              }
              ListHeaderComponent={() =>
                renderHeader(setLinkOpen)
              }
              ListEmptyComponent={renderEmpty}
              showsVerticalScrollIndicator={false}
            />
            
        </ScrollView>
        <View style={style.footer}>
          <CircleButton
            icon={Check}
            onPress={() => {
                const temp = props.uiCompleted;
                temp.links = true;
                props.setUiCompleted(temp);
                  handleConfirm();
                }}
          />
        </View>
      </View>
        <Modal
            open={linkOpen}
            onClose={() => {
                onChangeName("");
                onChangeLink("");
                setLinkType(null);
                setEditing(false);
                setOpen(false);
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                setLinkOpen(false);
            }
            }
            style={style.modalContainer}
        >
            <Box style={style.modalCard}>
                <View style={{paddingLeft: 15, paddingRight: 15}}>
                {!editing ? (
                <Text style={{...style.header, marginTop: 20}}>Add Link</Text>
                ) : (
                <Text style={{...style.header, marginTop: 20}}>Edit Link</Text>
                )}
                <TextSmallField
                    theme={"dark"}
                    value={name}
                    placeholder="Link Name"
                    onChangeText={onChangeName}
                    onBlur={() => setFocused("")}
                    onFocus={() => setFocused("NAME")}
                    autoCapitalize="words"
                    focused={focused === "NAME"}
                    maxLength={24}
                    setRef={nameRef}
                />
                <TextSmallField
                    theme={"dark"}
                    value={link}
                    placeholder="Link"
                    onChangeText={onChangeLink}
                    onFocus={() => setFocused("LINK")}
                    focused={focused === "LINK"}
                    maxLength={90}
                    setRef={linkRef}
                />
                <Select 
                    style={style.select}
                    sx={{
                    color: "white",
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '.MuiSvgIcon-root ': {
                        fill: "white !important",
                    },
                    }}
                    inputProps={{
                    MenuProps: {
                        MenuListProps: {
                            sx: {
                                backgroundColor: darkThemeBackground,
                            }
                        }
                    }
                }}
                    value={linkType || 'PLACEHOLDER_VALUE'}
                    onChange={(event) => setLinkType(event.target.value)}
                    renderValue={(value) => {
                        if (value === 'PLACEHOLDER_VALUE') {
                            return <span style={{ color: 'gray' }}>Select a link type</span>;
                        }
                        return value; 
                    }}
                >
                    <MenuItem value='PLACEHOLDER_VALUE' sx={{ display: 'none'  }}>
                        Select a link type
                    </MenuItem>
                    <MenuItem 
                        style={style.item}
                        value="Table"
                    >
                        <Image source={CrownIcon} style={{
                            ...style.iconStyle,
                            tintColor: white,
                        }} resizeMode="contain" />
                        Tables/Bottle Service
                    </MenuItem>
                    <MenuItem 
                        style={style.item}
                        value="Merchandise"
                    >
                        <Image source={MerchandiseIcon} style={{
                            ...style.iconStyle,
                            tintColor: white,
                        }} resizeMode="contain" />
                        Merchandise
                    </MenuItem>
                    <MenuItem 
                        style={style.item}
                        value="Website"
                    >
                        <Image source={WebIcon} style={{
                            ...style.iconStyle,
                            tintColor: white,
                        }} resizeMode="contain" />
                        Website
                    </MenuItem>
                    <MenuItem 
                        style={style.item}
                        value="Video"
                    >
                        <Image source={VideoCameraIcon} style={{
                            ...style.iconStyle,
                            tintColor: white,
                        }} resizeMode="contain" />
                        Promo Video
                    </MenuItem>
                    <MenuItem 
                        style={style.item}
                        value="Other"
                    >
                        <Image source={LinkIcon} style={{
                            ...style.iconStyle,
                            tintColor: white,
                        }} resizeMode="contain" />
                        Other
                    </MenuItem>
                </Select>
                {!editing ? (
                <ActionButton
                    title="Add"
                    onPress={() =>
                    handleAdd(
                        name,
                        link,
                        linkType,
                        links,
                        setLinks,
                        setLinkOpen,
                        setLinkType,
                        onChangeName,
                        onChangeLink,
                        setEditing,
                        setOpen
                    )
                    }
                    style={{ marginTop: 20 }}
                />
                ) : (
                <ActionButton
                    title="Save"
                    onPress={() =>
                    handleEdit(
                        name,
                        link,
                        linkType,
                        setLinkOpen,
                        links,
                        setLinks,
                        currentLinkIndex,
                        setCurrentLinkIndex,
                        setLinkType,
                        onChangeName,
                        onChangeLink,
                        setEditing,
                        setOpen
                    )
                    }
                    style={{ marginTop: 20 }}
                />
                )}
                </View>
            </Box>
        </Modal>
    </SafeAreaView>
  );
};

EventLinksView.propTypes = {
  links: PropTypes.array,
  setLinks: PropTypes.func.isRequired,
  navigate: PropTypes.object.isRequired,
};

export default EventLinksView;
