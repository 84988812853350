import React from "react";
import PropTypes from "prop-types";

import style from "./style";
import IconButton from "../../../../components/IconButton";
import { AppStoreDownload } from "../../utils/images";
import { ExternalLinkIcon } from "../../../../utils/icons";
import { TouchableOpacity, Text, View, Image, Linking } from "react-native";
import { black } from "../../../../utils/color";



const ExternalLinkRow = (props) => {

   

    const alreadyGoing = () => {
        return props.event["eventGoingUsers"].includes(props.userId);
    }

    return(
        <View style={style.row}>
                <TouchableOpacity
                    onPress={() =>
                        window.location.replace(
                            "https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983"
                        )
                    }
                    style={style.downloadbutton}
                >
                    <Image source={AppStoreDownload} style={style.image} />
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={props.onPress}
                    style={style.button}
                >
                    
                    <Text style={style.text}>
                        {alreadyGoing ? "Buy Tickets" : "Join event"}
                    </Text>
                    <IconButton
                        icon={ExternalLinkIcon}
                        style={style.icon}
                        theme={"dark"}
                        onPress={props.onPress}
                        tintColor={black}
                    />
                </TouchableOpacity>
            </View>
    );
}

export default React.memo(ExternalLinkRow);
