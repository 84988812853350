import React, { useEffect } from "react";
import { UiProvider } from "./uiContext";
import { EventProvider } from "./features/events/EventContext";
import LogRocket from 'logrocket';
import { CreateEventProvider } from "./features/events/create/CreateEventContext";
import { VenueProvider } from "./features/venues/VenueContext";
import { AuthProvider } from "./features/auth/AuthContext";
import EventDetailView from "./features/events/views/EventDetailView/EventDetailView.tsx";
import VenueDetailView from "./features/venues/views/VenueDetailView";
import AuthHomeScreen from "./features/auth/AuthHomeScreen";
import AuthPhoneScreen from "./features/auth/AuthPhoneScreen";

import AuthEmailScreen from "./features/auth/AuthEmailScreen";
import AuthCodeScreen from "./features/auth/AuthCodeScreen";
import AuthPasswordScreen from "./features/auth/AuthPasswordScreen";
import AuthRegisterScreen from "./features/auth/AuthRegisterScreen";

import RegistrationScreen from "./features/registration/RegistrationScreen";
import LegalHomeScreen from "./features/legal/screens/LegalHomeScreen";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LegalPrivacyScreen from "./features/legal/screens/LegalPrivacyScreen";
import LegalTermsScreen from "./features/legal/screens/LegalTermsScreen";
import PaymentsScreen from "./features/payments/screens/PaymentsScreen";
import EventTicketView from "./features/events/views/EventTicketView/EventTicketView";
import { PaymentsProvider } from "./features/payments/PaymentsContext";
import TicketsScreen from "./features/tickets/TicketsView";

import EventCreateScreen from "./features/events/create/screens/EventCreateScreen";
import EventNameScreen from "./features/events/create/screens/EventNameScreen";
import EventDescriptionScreen from "./features/events/create/screens/EventDescriptionScreen";
import EventTimeScreen from "./features/events/create/screens/EventTimeScreen";
import EventAddressScreen from "./features/events/create/screens/EventAddressScreen";
import EventPersonnelScreen from "./features/events/create/screens/EventPersonnelScreen";
import EventGuestsScreen from "./features/events/create/screens/EventGuestsScreen";
import EventLinksScreen from "./features/events/create/screens/EventLinksScreen";
import EventTicketsScreen from "./features/events/create/screens/EventTicketsScreen";
import EventHostingScreen from "./features/events/screens/EventHostingScreen";
import EventDetailsScreen from "./features/events/create/screens/EventDetailsScreen";
import RecurringEventDetailScreen from "./features/events/screens/RecurringEventDetailScreen";
import { RecurringEventProvider } from "./features/events/RecurringEventContext";
import SplashScreen from "./features/splash/SplashScreen";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "@sweetalert2/theme-dark/dark.scss";
import "./assets/fontImport.css";
import { useDeviceContext } from "twrnc";

LogRocket.init('avenu/avenu');

function App() {
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
            .swal2-container {
                z-index: 10000;
            }
            .swal2-popup {
                font-size: 1rem !important;
                font-family: Barlow;
              }
        `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <UiProvider>
        <EventProvider>
          <RecurringEventProvider>
            <CreateEventProvider>
              <VenueProvider>
                <AuthProvider>
                  <PaymentsProvider>
                      <BrowserRouter>
                        <div id="recaptcha-container" />
                        <Routes>
                          <Route path="" element={<SplashScreen />} />
                          <Route path="/event" element={<EventDetailView />} />
                          <Route
                            path="/recurring_event"
                            element={<RecurringEventDetailScreen />}
                          />
                          <Route
                            path="/event/host"
                            element={<EventHostingScreen />}
                          />
                          <Route
                            path="/event/tickets"
                            element={<EventTicketView />}
                          />
                          <Route
                            path="/event/create"
                            element={<EventCreateScreen />}
                          />
                          <Route
                            path="/event/create/name"
                            element={<EventNameScreen />}
                          />
                          <Route
                            path="/event/create/description"
                            element={<EventDescriptionScreen />}
                          />
                          <Route
                            path="/event/create/time"
                            element={<EventTimeScreen />}
                          />
                          <Route
                            path="/event/create/location"
                            element={<EventAddressScreen />}
                          />
                          <Route
                            path="/event/create/personnel"
                            element={<EventPersonnelScreen />}
                          />
                          <Route
                            path="event/create/tickets"
                            element={<EventTicketsScreen />}
                          />
                          <Route
                            path="event/create/guests"
                            element={<EventGuestsScreen />}
                          />
                          <Route
                            path="event/create/links"
                            element={<EventLinksScreen />}
                          />
                          <Route
                            path="/event/edit"
                            element={<EventCreateScreen />}
                          />
                          <Route
                            path="/event/edit/details"
                            element={<EventDetailsScreen />}
                          />
                          <Route
                            path="/event/edit/location"
                            element={<EventAddressScreen />}
                          />
                          <Route
                            path="/event/edit/personnel"
                            element={<EventPersonnelScreen />}
                          />
                          <Route
                            path="event/edit/tickets"
                            element={<EventTicketsScreen />}
                          />
                          <Route
                            path="event/edit/guests"
                            element={<EventGuestsScreen />}
                          />
                          <Route
                            path="event/edit/links"
                            element={<EventLinksScreen />}
                          />
                          <Route path="/venue" element={<VenueDetailView />} />
                          <Route
                            path="/auth/home"
                            element={<AuthHomeScreen />}
                          />
                          <Route
                            path="/auth/phone"
                            element={<AuthPhoneScreen />}
                          />
                          <Route
                            path="/auth/email"
                            element={<AuthEmailScreen />}
                          />
                          <Route
                            path="/auth/code"
                            element={<AuthCodeScreen />}
                          />
                          <Route
                            path="/auth/password"
                            element={<AuthPasswordScreen />}
                          />
                          <Route
                            path="/auth/register"
                            element={<AuthRegisterScreen />}
                          />
                          <Route
                            path="/auth/signup"
                            element={<RegistrationScreen />}
                          />
                          <Route
                            path="/auth/legal"
                            element={<LegalHomeScreen />}
                          />
                          <Route
                            path="/auth/privacy"
                            element={<LegalPrivacyScreen />}
                          />
                          <Route
                            path="/auth/terms"
                            element={<LegalTermsScreen />}
                          />
                          <Route path="/stripe" element={<PaymentsScreen />} />
                          <Route path="/tickets" element={<TicketsScreen />} />
                        </Routes>
                      </BrowserRouter>
                  </PaymentsProvider>
                </AuthProvider>
              </VenueProvider>
            </CreateEventProvider>
          </RecurringEventProvider>
        </EventProvider>
      </UiProvider>
    </LocalizationProvider>
  );
}
export default App;
