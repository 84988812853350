import { StyleSheet } from "react-native";
import { color, sky, snow } from "../../utils/color";

export default StyleSheet.create({
    container: {
        justifyContent: "center",
        alignItems: "center",

        marginBottom: 12,
    },

    image: {
        width: 96,
        height: 96,

        borderRadius: 48,
        marginBottom: 24,
        backgroundColor: "whitesmoke",
    },

    row: {
        flexDirection: "row",
    },

    asterisk: {
        color: color,
        fontWeight: "700",
        fontSize: 18,
    },

    text: { color: snow },
    bubbleStyle: { backgroundColor: sky },
});
