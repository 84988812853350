import React, {
  useEffect,
  useContext,
  useState,
  useMemo,
  useCallback,
} from "react";
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  TextInput,
  ScrollView,
  Image,
} from "react-native";
import tw from "twrnc";
import TicketRow from "../../components/TicketRow/TicketRow";
import { useNavigate } from "react-router-dom";
import { EventContext } from "../../EventContext";
import { PaymentsContext } from "../../../payments/PaymentsContext";
import BubbleButton from "../../../../components/BubbleButton";
import { AppStoreDownload } from "../../utils/images";
import NavHeader from "../../../../components/NavHeader/NavHeader";
import { convertIntegerAmountToDollarString } from "../../../../utils/text";
import { AuthContext } from "../../../auth/AuthContext";
import { darkThemeBackground, sky } from "../../../../utils/color";

interface TicketType {
  ticketName: string;
  ticketPrice: number;
  ticketDescription?: string;
  ticketSku: string;
  ticketCode?: string;
  ticketLimit?: number;
  ticketsPurchased?: number;
  ticketPerGuestLimit?: number;
  isLockedTicket?: boolean;
  isScheduledTicket?: boolean;
  releaseTime?: string;
  ticketDeleted?: boolean;
}

interface Event {
  id: string;
  eventTicketTypes: TicketType[];
}

const EventTicketView: React.FC = () => {
  const [hiddenTicketCode, setHiddenTicketCode] = useState<string>("");
  const [isInputFocused, setInputFocused] = useState<boolean>(false);
  const [refreshKey, setRefreshKey] = useState<number>(0);

  const navigate = useNavigate();

  const { userAuth, setOnLoginCallbackObj } = useContext(AuthContext);

  const { event, perGuestTicketCounts, fetchTicketSkuCounts } =
    useContext(EventContext);

  const { paymentTotal, unlockedTickets, setUnlockedTickets, setTicketInfo } =
    useContext(PaymentsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (event && userAuth?.user?.uid) {
      fetchTicketSkuCounts(event.eventTicketTypes, userAuth.user.uid);
    }
  }, [event]);

  const forceRefresh = (): void => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const getRealPrice = (listedPrice: number): number => {
    return (listedPrice + 100) / 0.95;
  };

  const findTicketIndex = useCallback(
    (event: Event, ticketSku: string): number => {
      if (!event.eventTicketTypes || !Array.isArray(event.eventTicketTypes)) {
        return -1;
      }
      return event.eventTicketTypes.findIndex(
        (ticket) => ticket.ticketSku === ticketSku
      );
    },
    []
  );

  const renderTicketRow = (item: TicketType): JSX.Element | null => {
    if (item.ticketDeleted) return null;
    const isSoldOut =
      item.ticketLimit &&
      item.ticketsPurchased &&
      item.ticketsPurchased >= item.ticketLimit;

    return (
      <TicketRow
        ticketName={item.ticketName}
        ticketPrice={item.ticketPrice}
        feesText={
          item.ticketPrice > 0 &&
          `($${convertIntegerAmountToDollarString(
            getRealPrice(item.ticketPrice)
          )} with fees)`
        }
        ticketDescription={item.ticketDescription ?? ""}
        index={findTicketIndex(event, item.ticketSku)}
        isTicketLocked={item.isLockedTicket}
        isScheduledTicket={item.isScheduledTicket ?? false}
        releaseTime={item.isScheduledTicket ? item.releaseTime : undefined}
        isTicketHidden={item.ticketCode ? item.ticketCode !== "" : false}
        soldOut={isSoldOut}
        display={false}
        maxTicketsPerGuest={(() => {
          if (isSoldOut) return 0;
          const guestPurchasedCount = perGuestTicketCounts[item.ticketSku] || 0;
          return item.ticketPerGuestLimit - guestPurchasedCount;
        })()}
      />
    );
  };

  const handleCodeChange = (code: string): void => {
    const sanitizedCode = code.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
    setHiddenTicketCode(sanitizedCode);
  };

  const handleUnlock = (): void => {
    const matchingTickets = event.eventTicketTypes.filter(
      (type) => type.ticketCode === hiddenTicketCode
    );
    if (matchingTickets.length > 0) {
      setUnlockedTickets([...matchingTickets, ...unlockedTickets]);
      forceRefresh();
    } else {
      window.alert(
        `The ticket code "${hiddenTicketCode}" you entered is invalid or expired.`
      );
    }
  };

  const handleCodeFocus = (): void => {
    setInputFocused(true);
  };

  const handleCodeBlur = (): void => {
    setInputFocused(false);
  };

  const ticketTypes = useMemo((): TicketType[] => {
    return event
      ? event.eventTicketTypes.filter((type) => !type.ticketCode)
      : [];
  }, [event]);

  const unlockedTicketTypes = useMemo((): TicketType[] => {
    return unlockedTickets.length > 0
      ? event.eventTicketTypes.filter((type) => unlockedTickets.includes(type))
      : [];
  }, [event, unlockedTickets]);

  const sortedData = useMemo((): TicketType[] => {
    const combinedData = [...ticketTypes, ...unlockedTicketTypes];

    combinedData.sort((a, b) => {
      const aUnlocked = unlockedTickets.includes(a);
      const bUnlocked = unlockedTickets.includes(b);

      const aSoldOut =
        a.ticketLimit &&
        a.ticketsPurchased &&
        a.ticketsPurchased >= a.ticketLimit;
      const bSoldOut =
        b.ticketLimit &&
        b.ticketsPurchased &&
        b.ticketsPurchased >= b.ticketLimit;

      if (aUnlocked && !aSoldOut && (!bUnlocked || bSoldOut)) return -1;
      if (bUnlocked && !bSoldOut && (!aUnlocked || aSoldOut)) return 1;

      if (aSoldOut && !bSoldOut) return 1;
      if (bSoldOut && !aSoldOut) return -1;

      return 0;
    });

    return combinedData;
  }, [ticketTypes, unlockedTicketTypes]);

  useEffect(() => {
    console.log("sorted ticket Data", sortedData);
  }, [sortedData]);

  const updateMapping = useCallback((): void => {
    const newTicketInfo: Record<number, string> = {};

    sortedData.forEach((item, index) => {
      newTicketInfo[index] = item.ticketName;
    });

    setTicketInfo(newTicketInfo);
  }, [sortedData, setTicketInfo]);

  return (
    <View
      style={tw`flex-1 bg-[${darkThemeBackground}] p-2.5 h-screen`}
      key={refreshKey}
    >
      <NavHeader
        onPress={() => {
          navigate(`/event?eventId=${event.id}`);
        }}
      />
      <Text style={tw`text-white font-bold text-2xl mt-10 mb-6 self-center`}>
        Select Passes and Items
      </Text>
      <View style={tw`flex-1 flex-row items-center md:flex-col w-full`}>
        <ScrollView style={tw`mb-20 w-full`}>
          <View style={tw`flex-col items-center justify-center mb-4`}>
            <TextInput
              style={tw`h-10 border border-white text-white rounded-lg w-80 text-center`}
              value={hiddenTicketCode}
              placeholder={isInputFocused ? "" : "Hidden Code"}
              onFocus={handleCodeFocus}
              onBlur={handleCodeBlur}
              onChangeText={handleCodeChange}
            />
            <BubbleButton
              bubbleStyle={tw`text-red-500 h-10 w-80`}
              title="Unlock Hidden Passes or Items"
              onPress={handleUnlock}
              disabled={!hiddenTicketCode}
            />
          </View>

          {event ? (
            <FlatList
              style={tw`flex-1 w-full`}
              data={sortedData}
              renderItem={({ item }) => renderTicketRow(item)}
            />
          ) : null}
          <View style={tw`items-center justify-center`}>
            <Text style={tw`text-white text-base text-center px-2.5`}>
              Download Avenu on App Store to receive notifications about the
              event & ticket releases, add friends, meet people, and post
              pictures!
            </Text>
            <TouchableOpacity
              onPress={() =>
                window.location.replace(
                  "https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983"
                )
              }
            >
              <Image source={AppStoreDownload} style={tw`w-32 h-32`} />
            </TouchableOpacity>
          </View>
        </ScrollView>
        <View
          style={tw`flex-1 ml-2.5 fixed bottom-2.5 left-2.5 right-2.5 justify-center`}
        >
          <View
            style={tw`${!unlockedTickets.length ? "mb-[90px]" : "mb-2.5"}`}
          ></View>
        </View>
      </View>

      <View
        style={tw`fixed bottom-2.5 left-2.5 right-2.5 justify-center bg-[${darkThemeBackground}]`}
      >
        <Text style={tw`text-base text-white my-2.5`}>
          Total Payment: ${(paymentTotal / 100).toFixed(2)}
        </Text>
        {event && userAuth?.user?.uid ? (
          <TouchableOpacity
            style={tw`bg-[${sky}] flex-row justify-center rounded-xl border-2 border-white py-3`}
            onPress={() => {
              updateMapping();
              navigate("/stripe" + window.location.search);
            }}
          >
            <Text style={tw`font-bold text-sm`}>Confirm</Text>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            style={tw`bg-[${sky}] flex-row justify-center rounded-xl border-2 border-white py-3`}
            onPress={() => {
              setOnLoginCallbackObj({
                func: () => {
                  return "/stripe" + window.location.search;
                },
                name: "navToStripe",
              });
              navigate("/auth/home");
            }}
          >
            <Text style={tw`font-bold text-sm`}>
              Login / Register to purchase
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default EventTicketView;
