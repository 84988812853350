import React, { useState, useRef } from "react";
import {
    View,
    Text,
    KeyboardAvoidingView,
    SafeAreaView,
    Platform,
} from "react-native";
import NavHeader from "../../../../../components/NavHeader/NavHeader";
import TextField from "../../../../../components/TextField";
import CircleButton from "../../../../../components/CircleButton";
import { Check } from "../../../../../utils/images";
import { EVENT_NAME_LIMIT } from "../../../../../utils/constants";
import style from "./style";

const EventNameView = (props) => {
    const [name, onChangeName] = useState(props.name);
    const [focused, setFocused] = useState("");
    const nameRef = useRef();

    const handleKeyPress = (event) => {
        if (event.nativeEvent.key === "Enter") {
            console.log(name !== "");
            event.preventDefault();
            if (name !== "") {
                props.onChangeName(name);
                props.navigate("/event/create/description");
            }
        }
    };

    return (
        <SafeAreaView style={style.screen}>
            <NavHeader
                onPress={() => {
                    props.navigate(-1);
                }}
            />
            <View style={style.container}>
                <View style={style.wrapper}>
                    <Text style={style.header}>
                        What&apos;s your event&apos;s name?
                    </Text>
                    <View style={style.row}>
                        <TextField
                            value={name}
                            placeholder="Event name"
                            onChangeText={onChangeName}
                            onKeyPress={handleKeyPress}
                            onBlur={() => setFocused("")}
                            onFocus={() => setFocused("NAME")}
                            autoCapitalize="words"
                            focused={focused === "NAME"}
                            setRef={nameRef}
                            maxLength={EVENT_NAME_LIMIT}
                        />
                    </View>
                </View>
                <View style={style.spacer} />
                <KeyboardAvoidingView
                    keyboardVerticalOffset={44}
                    behavior={Platform.OS === "ios" ? "padding" : "height"}
                >
                    <View style={style.footer}>
                        <CircleButton
                            icon={Check}
                            onPress={() => {
                                props.onChangeName(name);
                                props.navigate("/event/create/description");
                            }}
                            disabled={name === ""}
                        />
                    </View>
                </KeyboardAvoidingView>
            </View>
        </SafeAreaView>
    );
};

export default EventNameView;
