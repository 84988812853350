import React from "react";
import {
  View,
  FlatList,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import tw from "twrnc";
import { DefaultProfileImage, BlueCheckmark } from "../../utils/images";
import { User } from "../../../../utils/types";

interface HostsRowProps {
  eventHostsUsers: User[];
}

const renderHostPhoto = ({ item }: { item: User }) => {
  return (
    <TouchableOpacity
      onClick={() =>
        window.location.replace(
          "https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983"
        )
      }
      style={tw`h-[75px] w-[75px] rounded-full border-2 border-white mr-0.5`}
    >
      <img
        key={item.userPhone}
        src={
          item.userMedia.length !== 0 ? item.userMedia[0] : DefaultProfileImage
        }
        style={tw`mr-3 h-[72px] w-[72px] rounded-full`}
      />
      {item.userVerified ? (
        <Image
          style={tw`absolute right-0 bottom-0 w-[30px] h-[30px]`}
          source={BlueCheckmark}
        />
      ) : null}
    </TouchableOpacity>
  );
};

const HostsRow: React.FC<HostsRowProps> = ({ eventHostsUsers }) => (
  <View style={tw`bg-black rounded-[20px] shadow-lg`}>
    <View style={tw`flex-row w-full items-center`}>
      <img
        src={require("../../../../assets/icons/icon-host.png")}
        style={tw`w-[35px] h-[35px] mt-[15px] ml-[15px]`}
      />
      <Text style={tw`text-white text-xl font-bold mb-3 ml-5 mt-5`}>Hosts</Text>
    </View>
    <View
      style={tw`h-[100px] w-full mx-5 mb-2.5 mt-1.5 self-center overflow-hidden flex-row`}
    >
      <FlatList
        style={tw`ml-4 mb-0 self-center`}
        contentContainerStyle={tw`flex-row items-center`}
        horizontal={true}
        data={eventHostsUsers}
        renderItem={renderHostPhoto}
        keyExtractor={(_, index) => index.toString()}
        showsHorizontalScrollIndicator={false}
      />
    </View>
  </View>
);

export default HostsRow;
