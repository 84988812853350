import React from "react";
import PropTypes from "prop-types";
import { View, Image } from "react-native";
import Tooltip from "../../../../components/Tooltip";
import { green } from "../../../../utils/color";
import { AvenuArrow } from "../../../auth/images";
import style from "./style";

const getIconStyle = (tintColor, theme, size) => ({
    ...style.icon,
    tintColor: tintColor || (theme === "dark" ? green : green),
    width: size || 24,
    height: size || 24,
});

const GuestListAvenuMeetTooltip = ({ theme, venue }) => (
    <>
        <Tooltip
            theme="dark"
            header="AvenuMeet"
            text={venue ? 
                "Join the venue to add friends or anonymously request to Meet through Avenu.\n\nIf the Meet request is mutual, it's a Green Light! You'll both be notified and can message each other." :
                "Join the event to add friends or anonymously request to Meet through Avenu.\n\nIf the Meet request is mutual, it's a Green Light! You'll both be notified and can message each other."
            }
        />
        <View
            style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 20,
                justifyContent: "center",
            }}
        >
            <Image source={AvenuArrow} style={getIconStyle(null, theme, 50)} />
        </View>
    </>
);

GuestListAvenuMeetTooltip.propTypes = {
    theme: PropTypes.string,
    venue: PropTypes.bool
};

export default React.memo(GuestListAvenuMeetTooltip);
