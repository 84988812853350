import { StyleSheet } from "react-native";

import { snow, ash, black } from "../../utils/color";
import { family, body } from "../../utils/font";

export default StyleSheet.create({
  bigContainer: {
    height: 80,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between"
  },

  smallContainer: {
    flexDirection: "row",
  },

  image: {
    width: 48,
    height: 48,

    marginRight: 12,
    borderRadius: 24,
  },

  line: {
    width: "100%",
    height: 1,
  },

  row: {
    height: 80,
  },

  spacer: {
    marginLeft: 250
  },

  title: {
    marginBottom: 2,

    fontFamily: family,
    fontSize: 20,

    color: "white",
  },

  titleDisabled: {
    color: ash,
  },
});
