import React, { useContext, useState } from "react";
import {
  Keyboard,
  SafeAreaView,
  TouchableWithoutFeedback,
  View,
  Text,
} from "react-native";
import { getCountryCallingCode } from "react-phone-number-input";
import ReactFlagsSelect from "react-flags-select";
import tw from "twrnc";
import BubbleTextField from "../../components/BubbleTextField";
import BubbleButton from "../../components/BubbleButton";
import RatioImage from "../../components/RatioImage";
import { AvenuArrow, AvenuNameNew } from "./images";
import { darkThemeBackground } from "../../utils/color";
import { useScreen } from "../../hooks/useScreen";
import "./react-phone-number-input-style.css";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";

const AuthPhoneView: React.FC = () => {
  const [phone, onChangePhone] = useState<string>("");
  const [selected, setSelected] = useState<string>("US");
  const [countryNum, setCountryNum] = useState<string>("1");

  const { width } = useScreen();
  const navigate = useNavigate();
  const { code } = useContext(AuthContext);

  const PHONE_REGEX = /[0-9]{3}$/;

  const handleChangePhone = (
    event: any,
    onChangePhone: (value: string) => void,
    countryNum: string
  ) => {
    let split = event.target.value.split(" ");
    let nonCountryNumber = "";
    if (split.length > 1) {
      nonCountryNumber = split[1];
    } else {
      nonCountryNumber = split[0];
    }
    let next = nonCountryNumber.replace(/(\+1 )|-/g, "");
    next = `${next.substring(0, 3)}-${next.substring(3, 6)}-${next.substring(6)}`;
    next = next.replace(/-+$/, "");
    next = next !== "" ? `+${countryNum} ${next}` : next;
    onChangePhone(next);
  };

  const convertPhoneToNumsOnly = (num: string) => {
    let next = num.replace(/(\+)|-/g, "");
    next = next.replace(/[-+]/, "");
    next = next !== "" ? `+${next}` : next;
    return next;
  };

  const bubbleAction = (phone: string) => {
    code(phone);
    navigate("/auth/code");
  };

  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <SafeAreaView
        style={[
          tw`flex-1 justify-start items-center h-screen`,
          { backgroundColor: darkThemeBackground, minHeight: "100vh" },
        ]}
      >
        <View style={tw`m-8 mb-[150px] rounded-lg shadow-xl`}>
          <RatioImage image={AvenuNameNew} size={70} ratio={3.64} />
        </View>
        <View style={[tw`mx-6 z-10`, { width: width * 0.85 }]}>
          <ReactFlagsSelect
            selected={selected}
            onSelect={(code: string) => {
              setSelected(code);
              console.log("code", code);
              console.log("callingCode:", getCountryCallingCode(code as any));
              setCountryNum(getCountryCallingCode(code as any));
            }}
            showSelectedLabel={false}
            showSecondarySelectedLabel={false}
            placeholder={""}
            searchPlaceholder=""
            className={"countryPicker"}
            selectButtonClassName={"countryPickerButton"}
            countries={[
              "AU",
              "AT",
              "BE",
              "CA",
              "CN",
              "DK",
              "FR",
              "DE",
              "IN",
              "IT",
              "JP",
              "MX",
              "NZ",
              "NO",
              "PT",
              "SG",
              "ZA",
              "ES",
              "SE",
              "CH",
              "NL",
              "TR",
              "GB",
              "US",
            ]}
          />
        </View>
        <BubbleTextField
          style={{ width: width * 0.85 }}
          text={phone}
          placeholder="Enter Mobile Number"
          onChange={(event) =>
            handleChangePhone(event, onChangePhone, countryNum)
          }
          onSubmit={() => {
            if (phone.match(PHONE_REGEX)) {
              bubbleAction(convertPhoneToNumsOnly(phone));
            }
          }}
          keyboardType="number"
          inputMode="numeric"
          pattern="[0-9]*"
        />
        <BubbleButton
          title={"Enter"}
          onPress={(event: React.MouseEvent) => {
            event.preventDefault();
            bubbleAction(convertPhoneToNumsOnly(phone));
          }}
          bubbleStyle={[tw`shadow-xl`, { width: width * 0.85 }]}
          color={"#8ADDFF"}
          titleStyle={tw`text-black text-[19px]`}
          disabled={!phone.match(PHONE_REGEX)}
        />
        <Text style={tw`py-4 pb-8 px-5 text-sm w-full text-white text-center`}>
          Creating an Avenu profile is an opt-in to receive SMS texts from hosts
          & friends about event invites, announcements, reminders, and recaps.
        </Text>
        <RatioImage
          image={AvenuArrow}
          size={75}
          ratio={1}
          style={tw`tint-white`}
        />
      </SafeAreaView>
    </TouchableWithoutFeedback>
  );
};

export default AuthPhoneView;
