import React, { useState, useEffect, useContext } from "react";
import {
    Keyboard,
    SafeAreaView,
    TouchableWithoutFeedback,
    View,
} from "react-native";
import { useNavigate } from "react-router-dom";
import tw from "twrnc";
import { AuthContext } from "./AuthContext";
import BubbleButton from "../../components/BubbleButton";
import BubbleTextField from "../../components/BubbleTextField"; 
import RatioImage from "../../components/RatioImage";
import { AvenuArrow, AvenuNameNew } from "./images";
import { useScreen } from "../../hooks/useScreen";
import { darkThemeBackground, color } from "../../utils/color";

const AuthEmailScreen: React.FC = () => {
    const [email, onChangeEmail] = useState<string>("");
    const { navigateSignIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const { width } = useScreen();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <SafeAreaView style={[
                tw`flex-1 justify-start items-center h-screen`,
                { backgroundColor: darkThemeBackground, minHeight: "100vh" }
            ]}>
                <View style={tw`m-8 mb-50 rounded-lg shadow-lg shadow-black/60`}>
                    <RatioImage image={AvenuNameNew} size={70} ratio={3.64} />
                </View>
                <BubbleTextField
                    style={tw.style("w-[85%] mx-auto")}
                    text={email}
                    placeholder="Enter Email"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeEmail(event.target.value)}
                    onSubmit={() => {
                        navigateSignIn(email, navigate);
                    }}
                    inputMode="string"
                />
                <BubbleButton
                    title="Enter"
                    onPress={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.preventDefault();
                        navigateSignIn(email, navigate);
                    }}
                    bubbleStyle={tw.style("shadow-lg shadow-black/60", {
                        width: width * 0.85,
                        backgroundColor: color
                    })}
                    titleStyle={tw`text-black`}
                />
                <RatioImage
                    image={AvenuArrow}
                    size={75}
                    ratio={1}
                    style={tw`mt-[50px] tint-white`}
                />
            </SafeAreaView>
        </TouchableWithoutFeedback>
    );
};

export default AuthEmailScreen;
