import React, { useState, useEffect, useContext } from "react";
import { FlatList, Modal, Text, View, TouchableOpacity } from "react-native";
import { useNavigate } from "react-router-dom";
import tw from "twrnc";
import db from "../../firebaseConfig.js";
import { doc, getDoc, getDocs, query, collection, where, orderBy, startAfter, limit, DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { AuthContext } from "../auth/AuthContext";
import { EventContext } from "../events/EventContext.js";
import NavHeader from "../../components/NavHeader/NavHeader";
import ItemRow from "../../components/ItemRow/index.js";
import TicketModal from "./TicketModal";
import BubbleButton from "../../components/BubbleButton/index.js";
import { QRIcon } from "../../utils/icons.js";
import { DefaultEventImage } from "../../utils/images.js";
import { color, darkThemeBackground } from "../../utils/color.js";
import { Ticket } from "../../utils/types.js";

const showTicket = async (ticket: Ticket, setTicket: Function) => {
    setTicket({
        data: ticket,
        ticketQuantity: ticket.ticketQuantity,
        ticketType: ticket.ticketType,
        ticketTitle: ticket.ticketTitle,
        eventRefCode: ticket.eventRefCode,
        visible: true,
    });
};

const hideTicket = (setTicket: Function) => {
    setTicket({
        data: null,
        ticketQuantity: null,
        ticketType: null,
        ticketTitle: null,
        visible: false,
        eventRefCode: null,
    });
};

const renderTicketRow = (item: Ticket, navigation: Function, setTicket: Function) => {
    return item.ticketEvent ? (
        <ItemRow
            theme={"dark"}
            title={item.ticketTitle}
            subtitle={item.ticketName}
            image={item.ticketMedia}
            icons={[
                {
                    icon: QRIcon,
                    onPress: () => showTicket(item, setTicket),
                },
            ]}
            default={DefaultEventImage}
            onPress={() => showTicket(item, setTicket)}
        />
    ) : null;
};

const keyExtractor = (item: Ticket) => item.id;

const TicketsScreen: React.FC = () => {
    const navigate = useNavigate();
    const [userTickets, setUserTickets] = useState<Ticket[]>([]);
    const [ticket, setTicket] = useState({
        data: null,
        ticketQuantity: null,
        ticketType: null,
        ticketTitle: null,
        visible: false,
        eventRefCode: null,
    });

    const {
        userAuth,
        user,
        readUserIfPresent,
    } = useContext(AuthContext);

    const {
        event,
    } = useContext(EventContext);

    const readUserTickets = async (cursorTickets?: number): Promise<Ticket[]> => {
        if (!userAuth?.user?.uid) return [];

        let q = query(
            collection(db, "tickets"), 
            where("ticketUser", "==", userAuth.user.uid), 
            orderBy("createdAt", "desc"), 
            startAfter(cursorTickets ? new Date(cursorTickets) : new Date()), 
            limit(20)
        );
        
        let querySnapshot = await getDocs(q);
        const localUserTickets: { [key: string]: Ticket } = {};

        await Promise.all(querySnapshot.docs.map(async (documentSnapshot: QueryDocumentSnapshot<DocumentData>) => {
            const data = documentSnapshot.data() as Ticket;
            localUserTickets[documentSnapshot.id] = data;
            
            const temp = localUserTickets[documentSnapshot.id].ticketEvent;
            const docRef = doc(db, 'events', temp);
            const docSnapshot = await getDoc(docRef);
            
            if (docSnapshot.exists()) {
                let eventData = docSnapshot.data();
                localUserTickets[documentSnapshot.id].eventRefCode = eventData.eventRefCode;
            }
            localUserTickets[documentSnapshot.id].id = documentSnapshot.id;
        }));

        if (userTickets.length > 0) {
            userTickets.forEach((ticket) => {
                localUserTickets[ticket.id] = ticket;
            });
        }

        let userTicketsListUnsorted = Array.from(Object.values(localUserTickets));
        let userTicketsList = userTicketsListUnsorted.sort((a, b) => 
            b?.createdAt.seconds - a?.createdAt.seconds
        );

        setUserTickets(userTicketsList);
        return userTicketsList;
    };

    useEffect(() => {
        console.log("fired Tickets screen");
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        readUserTickets();
        if (userAuth) {
            readUserIfPresent(userAuth.user.uid);
        }
    }, [userAuth]);

    useEffect(() => {
        readUserTickets();
        const timer = setTimeout(() => readUserTickets(), 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <View style={[tw`flex-1 w-full justify-start items-start bg-[${darkThemeBackground}] h-screen`, { minHeight: "100vh" }]}>
            <NavHeader
                onPress={() => {
                    navigate("/event?eventId=" + event.id);
                }}
                onAddTicket={() => {
                    navigate("/event/tickets?eventId=" + event.id);
                }}
                title="My Passes"
            />

            <View style={tw`w-full h-[1px] bg-gray-500 self-center`} />
            <View style={tw`flex-1 w-full px-6 bg-[${darkThemeBackground}]`}>
                {userAuth ? (
                    <FlatList
                        style={tw`flex-1 w-full`}
                        data={userTickets}
                        renderItem={({ item }) => renderTicketRow(item, navigate, setTicket)}
                        keyExtractor={keyExtractor}
                        initialNumToRender={20}
                        maxToRenderPerBatch={20}
                        onEndReached={() => {
                            if (userTickets.length > 0) {
                                readUserTickets(userTickets[userTickets.length - 1]?.createdAt.seconds * 1000);
                            }
                        }}
                        onEndReachedThreshold={0.5}
                        onRefresh={() => readUserTickets()}
                        refreshing={false}
                        ListEmptyComponent={
                            <View style={tw`flex-1 justify-center`}>
                                <Text style={tw`text-white self-center mt-[5vmin]`}>
                                    You don't have any tickets yet. Get some for this event!
                                </Text>
                                <BubbleButton
                                    title={"Get Tickets"}
                                    bubbleStyle={{
                                        backgroundColor: color,
                                        borderWidth: 2,
                                        borderColor: "white",
                                    }}
                                    onPress={() => {
                                        navigate("/event/tickets?eventId=" + event.id);
                                    }}
                                />
                            </View>
                        }
                    />
                ) : (
                    <TouchableOpacity
                        onPress={() => navigate("/auth/home")}
                        style={tw`self-center rounded p-2.5 mt-5 border border-white`}
                    >
                        <Text style={tw`text-white`}>
                            Login to view your tickets!
                        </Text>
                    </TouchableOpacity>
                )}
            </View>

            <Modal
                animationType="fade"
                visible={ticket.visible}
                transparent={true}
            >
                <TicketModal
                    ticketData={ticket.data}
                    ticketQuantity={ticket.ticketQuantity}
                    ticketType={ticket.ticketType}
                    ticketTitle={ticket.ticketTitle}
                    eventRefCode={ticket.eventRefCode}
                    user={user}
                    onDismiss={() => hideTicket(setTicket)}
                />
            </Modal>
        </View>
    );
};

export default TicketsScreen;
