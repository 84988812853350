import React from "react";
import { Image, Text, TouchableOpacity, View, ImageSourcePropType, ViewStyle } from "react-native";
import { Shadow } from "../../../../utils/images";
import { getDateTime } from "../../../../utils/time";
import { DefaultEventImage } from "../../../../utils/images";
import { Gradient1, Gradient2, Gradient3, Gradient4, Gradient5 } from "../../utils/images";
import moment from "moment";
import { white, air } from "../../../../utils/color";
import IconButton from "../../../../components/IconButton";
import tw from "twrnc";

interface InvitedByUser {
  userMedia: string[];
  userFirst: string;
}

interface EventRowProps {
  gradientIndex?: number;
  time: {
    seconds: number;
    nanoseconds: number;
  };
  onPress: () => void;
  style?: ViewStyle;
  image?: string;
  icon?: ImageSourcePropType;
  iconStyle?: ViewStyle;
  iconSize?: number;
  onPressIcon?: () => void;
  invitedBy?: InvitedByUser;
  title: string;
  recurring?: boolean;
  eventGuests: Record<string, any>;
  eventHosts?: string[];
  userId?: string;
  hideGuests?: boolean;
  venue: string;
  navigate: any;
  dayOfWeek?: string;
}

const EventRow: React.FC<EventRowProps> = ({
  gradientIndex,
  time,
  onPress,
  style,
  image,
  icon,
  iconStyle,
  iconSize,
  onPressIcon,
  invitedBy,
  title,
  recurring
}) => {
  const gradientImages = [Gradient1, Gradient2, Gradient3, Gradient4, Gradient5];
  const safeGradientIndex = gradientIndex !== undefined ? Math.min(Math.max(0, gradientIndex), gradientImages.length - 1) : 0;
  const gradientImage = gradientIndex !== undefined ? gradientImages[safeGradientIndex] : DefaultEventImage;

  const timeInMilliseconds = time.seconds * 1000 + time.nanoseconds / 1e6;
  const momentTime = moment(timeInMilliseconds);


  return (
    <TouchableOpacity onPress={onPress}>
      <View style={tw`w-80 h-80 md:w-96 md:h-96 lg:w-[40vw] lg:h-[40vw] rounded-lg my-1.5`}>
        {image ? (
          <Image source={{ uri: image }} style={tw`w-full h-full rounded-lg`} />
        ) : (
          <Image source={gradientImage} style={tw`w-full h-full rounded-lg`} />
        )}
        <Image source={Shadow} style={tw`absolute w-full h-3/10 bottom-0 resize-stretch `} />
        <View style={tw`absolute justify-between items-start w-full h-full`}>
          {icon ? 
            <IconButton 
              theme="dark"
              style={iconStyle}
              icon={icon}
              onPress={onPressIcon}
              size={iconSize}
            />
          : null}
          {invitedBy ? (
            <View style={tw`flex-row self-end`}>
              <Image source={{ uri: invitedBy.userMedia[0] }} style={tw`mt-4 mb-6 w-5 h-5 rounded-full`} />
              <Text style={[tw`text-sm mt-4.5 ml-2.5 mr-4`, { color: white }]}>{invitedBy.userFirst} invited you</Text>
            </View>
          ) : null}
          <View style={tw`self-end mr-3 mt-2.5`}>
          </View>
          <View style={tw`mb-0 w-full flex-row justify-between`}>
            <View>
              <Text style={[tw`mx-6 mb-0.5 font-bold text-lg`, { color: white }]}>{title}</Text>
              <Text style={[tw`mx-6 mb-2.5 text-base`, { color: air }]}>
                  {recurring 
                    ? `${momentTime.format('dddd')}s at ${momentTime.format('h:mm a')}`
                    : getDateTime(time)
                  }
              </Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default EventRow;
