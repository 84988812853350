import React, { useState, useEffect, ReactNode } from "react";
import db, { auth } from "../../firebaseConfig";
import LogRocket from "logrocket";

import {
  signInWithPhoneNumber,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword,
  UserCredential,
  ConfirmationResult,
} from "firebase/auth";
import {
  doc,
  setDoc,
  getDoc,
  getDocs,
  documentId,
  query,
  collection,
  where,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { User } from "../../utils/types";

interface AuthContextType {
  login: (code: string) => void;
  code: (phone: string) => void;
  confirm: ConfirmationResult | null;
  user: User | null;
  userFound: boolean | null;
  userAuth: UserCredential | null;
  createUser: (userId: string, userData: User) => Promise<void>;
  change: number;
  setChange: (change: number) => void;
  readUserIfPresent: (userId: string) => Promise<void>;
  navigateSignIn: (email: string, navigate: (path: string) => void) => void;
  loginEmail: (password: string) => void;
  signUpEmail: (password: string) => void;
  onLoginCallbackObj: any;
  setOnLoginCallbackObj: (obj: any) => void;
  error: Error | null;
  friends: User[];
}

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

function AuthProvider({ children }: { children: ReactNode }) {
  const [confirm, setConfirm] = useState<ConfirmationResult | null>(null);
  const [userAuth, setUserAuth] = useState<UserCredential | null>(null);
  const [user, setUser] = useState<any | null>(null);
  const [userFound, setUserFound] = useState<boolean | null>(null);
  const [change, setChange] = useState<number>(0);
  const [onLoginCallbackObj, setOnLoginCallbackObj] = useState<any>(null);
  const [friends, setFriends] = useState<User[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [email, setEmail] = useState<string | null>(null);

  const generateRecaptcha = (): void => {
    (window as any).recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response: any) => {},
      },
      auth
    );
  };

  const login = (code: string): void => {
    setChange(change + 1);
    confirm
      ?.confirm(code)
      .then((result: UserCredential) => {
        setUserAuth(result);
        console.log("DEBUG userAuth set", result);
      })
      .catch((error: Error) => {
        setError(error);
        console.log("ERROR in login: ", error);
      });
  };

  const code = (phone: string): void => {
    generateRecaptcha();
    const appVerifier = (window as any).recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult: ConfirmationResult) => {
        console.log("DEBUG confirm", confirmationResult);
        (window as any).confirmationResult = confirmationResult;
        setConfirm(confirmationResult);
      })
      .catch((error: Error) => {
        console.log("ERROR in code: ", error);
      });
  };

  const navigateSignIn = (
    email: string,
    navigate: (path: string) => void
  ): void => {
    console.log("email", email);
    setEmail(email);
    fetchSignInMethodsForEmail(auth, email)
      .then((methods: string[]) => {
        console.log("methods", methods);
        if (methods.length > 0) {
          navigate("/auth/password");
        } else {
          navigate("/auth/register");
        }
      })
      .catch((error: Error) => {
        console.log("ERROR in email fetch", error);
        navigate("/auth/register");
      });
  };

  const loginEmail = (password: string): void => {
    if (!email) return;
    setChange(change + 1);
    signInWithEmailAndPassword(auth, email, password)
      .then((result: UserCredential) => {
        setUserAuth(result);
        console.log("DEBUG userAuth set", result);
      })
      .catch((error: Error) => {
        setError(error);
        console.log("ERROR in login: ", error);
      });
  };

  const signUpEmail = (password: string): void => {
    if (!email) return;
    createUserWithEmailAndPassword(auth, email, password)
      .then((result: UserCredential) => {
        setUserAuth(result);
        console.log("DEBUG userAuth set", result);
      })
      .catch((error: Error) => {
        setError(error);
        console.log("ERROR in login: ", error);
      });
  };

  const readUserIfPresent = async (userId: string): Promise<void> => {
    setUserFound(null);
    const docSnap = await getDoc(doc(db, "users", userId));
    if (docSnap.exists()) {
      const userData = docSnap.data();
      userData.id = docSnap.id;
      setUser(userData);
      setUserFound(true);
      console.log("DEBUG found userData", userData);

      // Identify user in LogRocket
      LogRocket.identify(userId, {
        name: userData.name,
        email: userData.email,
        phone: userData.phone,
      });
    } else {
      setUserFound(false);
      console.log("DEBUG not found userData");
    }
  };

  const createUser = async (userId: string, userData: User): Promise<void> => {
    const userMedia: string[] = [];
    const storage = getStorage();
    let mediaUrl: string | null = null;

    if (userData.userMedia.length > 0) {
      await new Promise<void>((resolve, reject) => {
        userData.userMedia.forEach(async (media: any, index: number) => {
          const storageRef = ref(storage, `users/000${userId}-${index}.jpg`);

          await uploadBytes(storageRef, media).then((snapshot) => {
            console.log("Uploaded a blob or file!");
            getDownloadURL(snapshot.ref).then((downloadURL) => {
              mediaUrl = downloadURL;

              console.log("DEBUG mediaUrl", mediaUrl);
              if (mediaUrl === null) {
                throw new Error();
              }

              userMedia.push(mediaUrl);

              if (userMedia.length === userData.userMedia.length) resolve();
            });
          });
        });
      });
    }

    const newUser: User = {
      ...userData,
      userMedia,
      createdAt: new Date(),
    };

    console.log("DEBUG user id:", userId, "userData", userData);
    const tempUser = {
      ...userData,
      createdAt: new Date(),
    };
    const testID = "000" + userId;

    const docRef = doc(db, "users", userId);
    setDoc(docRef, newUser)
      .then(() => {
        readUserIfPresent(userId);
      })
      .catch((error) => {
        console.log(`createUser = (${userId}, ${userData}) => ${error}`);
      });
  };

  useEffect(() => {
    if (userAuth && userFound) {
      console.log("DEBUG saving user");
      localStorage.setItem("userAuth", JSON.stringify(userAuth));
    }
  }, [userAuth, userFound]);

  useEffect(() => {
    const localUserAuth = JSON.parse(
      localStorage.getItem("userAuth") || "null"
    );

    if (localUserAuth) {
      console.log("DEBUG loading user");
      setUserAuth(localUserAuth);
      setUserFound(true);
    }
  }, []);

  useEffect(() => {
    if (user) {
      async function fetchFriends(friends: string[]) {
        const userBatches: string[][] = [];
        for (let i = 0; i < friends.length / 10; i++) {
          userBatches.push(friends.slice(i * 10, (i + 1) * 10));
        }

        const batchRefs = userBatches.map(async (batch) => {
          return await getDocs(
            query(collection(db, "users"), where(documentId(), "in", batch))
          );
        });

        const friendsData: User[] = [];

        await Promise.all(batchRefs).then((querySnapshots) => {
          querySnapshots.forEach((querySnapshot) => {
            querySnapshot.forEach((documentSnapshot) => {
              const friend = documentSnapshot.data() as User;
              friend.id = documentSnapshot.id;
              friendsData.push(friend);
            });
          });
        });

        setFriends(friendsData);
      }
      fetchFriends(user.userFriends);
    }
  }, [user]);

  console.log("DEBUG auth vars", user, userAuth, error);

  return (
    <AuthContext.Provider
      value={{
        login: login,
        code: code,
        confirm: confirm,
        user: user,
        userFound: userFound,
        userAuth: userAuth,
        createUser: createUser,
        change: change,
        setChange: setChange,
        readUserIfPresent: readUserIfPresent,
        navigateSignIn: navigateSignIn,
        loginEmail: loginEmail,
        signUpEmail: signUpEmail,
        onLoginCallbackObj: onLoginCallbackObj,
        setOnLoginCallbackObj: setOnLoginCallbackObj,
        error: error,
        friends,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
