import React, { useState, useEffect, useContext } from "react";
import {
  Keyboard,
  SafeAreaView,
  TouchableWithoutFeedback,
  View,
  Text,
} from "react-native";
import tw from "twrnc";
import BubbleButton from "../../components/BubbleButton";
import BubbleTextField from "../../components/BubbleTextField";
import RatioImage from "../../components/RatioImage";
import { AvenuArrow, AvenuNameNew } from "./images";
import { AuthContext } from "./AuthContext";
import { UiContext } from "../../uiContext";
import { EventContext } from "../events/EventContext";
import { useNavigate } from "react-router-dom";
import { color, darkThemeBackground } from "../../utils/color";
import { useScreen } from "../../hooks/useScreen";

const AuthRegisterScreen: React.FC = () => {
  const [password, onChangePassword] = useState<string>("");
  const [confirmPassword, onChangeConfirm] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const {
    user,
    userFound,
    userAuth,
    signUpEmail,
    readUserIfPresent,
    onLoginCallbackObj,
   error : authError,
  } = useContext(AuthContext);
  const { url, setUrl } = useContext(UiContext);
  const { event, buyFreeTicket } = useContext(EventContext);
  const { width } = useScreen();

  useEffect(() => {
    console.log("DEBUG userAUth", userAuth);
    if (userAuth && Object.keys(userAuth).length !== 0) {
      readUserIfPresent(userAuth.user.uid);
    }
  }, [userAuth]);

  useEffect(() => {
    if (authError) {
      setIsLoading(false);
      setError('An error occurred while signing up, reload and try again');
    }
  }, [authError]);

  useEffect(() => {
    if (userFound === true) {
      console.log("DEBUG userfound..?", user, "userAUth:", userAuth);

      if (!onLoginCallbackObj) {
        navigate(-3);
        return;
      }

      if (onLoginCallbackObj && onLoginCallbackObj["func"] === buyFreeTicket) {
        onLoginCallbackObj["func"](userAuth.user.uid, event);
        console.log("DEBUG fired onLoginCallbackObj");
        navigate(url);
        setUrl(null);
      } else if (
        onLoginCallbackObj &&
        onLoginCallbackObj["name"] === "navToEventTicketBuy"
      ) {
        let navPath = onLoginCallbackObj["func"](
          userAuth.user.uid,
          event,
          onLoginCallbackObj["eventId"]
        );
        console.log("DEBUG navpath fired", navPath);
        navigate(navPath);
      } else if (
        onLoginCallbackObj &&
        onLoginCallbackObj["name"] === "navToTickets"
      ) {
        let navPath = onLoginCallbackObj["func"];
        console.log("navToTickets fired", navPath);
        navigate(navPath());
      } else {
        navigate(-3);
      }
    } else if (userFound === false) {
      navigate("/auth/signup");
    } else {
      console.log(
        "DEBUG this code should not run... except when resetting",
        userFound,
        user
      );
    }

    console.log("DEBUG user fired for some reason", user);
  }, [userFound]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSignupPressed = () => {
    setIsLoading(true);
    if (password !== confirmPassword) {
      setError("The two passwords do not match");
      setIsLoading(false);
    } else if (password.length < 6) {
      setError("Your password must be at least 6 characters long");
      setIsLoading(false);
    } else {
      setError("");
      signUpEmail(password);
    }
  };

  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <SafeAreaView
        style={[
          tw`flex-1 justify-start w-full items-center bg-[${darkThemeBackground}] h-screen`,
          { minHeight: "100vh" },
        ]}
      >
        <View style={tw`m-8 mb-[150px] rounded-[10px] shadow-lg`}>
          <RatioImage image={AvenuNameNew} size={70} ratio={3.64} />
        </View>
        <BubbleTextField
          style={tw`w-[85%]`}
          text={password}
          type={"password"}
          placeholder="Password"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChangePassword(event.target.value)
          }
        />
        <BubbleTextField
          style={tw`w-[85%]`}
          text={confirmPassword}
          type={"password"}
          placeholder="Confirm Password"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChangeConfirm(event.target.value)
          }
        />
        <BubbleButton
          title={isLoading ? "Signing up..." : "Enter"}
          onPress={onSignupPressed}
          bubbleStyle={[tw`w-full bg-[${color}]`, , { width: width * 0.85 }]}
          titleStyle={tw`text-black`}
          disabled={isLoading}
        />
        {error && (
          <Text style={tw`text-red-300 text-center mt-8 text-lg`}>{error}</Text>
        )}
        <RatioImage
          image={AvenuArrow}
          size={75}
          ratio={1}
          style={tw`mt-[50px] tint-white`}
        />
      </SafeAreaView>
    </TouchableWithoutFeedback>
  );
};

export default AuthRegisterScreen;
