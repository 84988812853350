import React, { useMemo } from "react";
import { View, Text } from "react-native-web";
import { getLocalDateTime } from "../../../../utils/time";
import { DateTime } from "luxon";
import tw from 'twrnc';

interface Coordinates {
    latitude: number;
    longitude: number;
}

interface TitleRowProps {
    title: string;
    start: DateTime;
    end: DateTime;
    coordinates: Coordinates;
}

const TitleRow: React.FC<TitleRowProps> = ({ start, end, coordinates, title }) => {
    const startDateTime = useMemo(
        () => getLocalDateTime(start, coordinates),
        [start, coordinates]
    );
    const endDateTime = useMemo(
        () => getLocalDateTime(end, coordinates),
        [end, coordinates]
    );

    const [startDay, startMonth, startDate, startTime] = useMemo(() => {
        const dateTimeStr = startDateTime.toFormat("ccc LLL dd t").toString();
        return dateTimeStr.split(" ");
    }, [startDateTime]);

    const [endDay, endMonth, endDate, endTime] = useMemo(() => {
        const dateTimeStr = endDateTime.toFormat("ccc LLL dd t").toString();
        return dateTimeStr.split(" ");
    }, [endDateTime]);

    const diffHours = end.toMillis() - start.toMillis();
    const ONE_DAY_MS = 24 * 60 * 60 * 1000;
    const ONE_WEEK_MS = 7 * ONE_DAY_MS;
    
    const moreThanDay = diffHours >= ONE_DAY_MS;
    const moreThanMonth = moreThanDay && startMonth !== endMonth;
    const multiDayLessThanWeek = moreThanDay && diffHours < ONE_WEEK_MS;

    return (
        <View style={tw`flex flex-row w-full bg-black rounded-[20px] shadow-lg`}>
            <View style={tw`w-[60%]`}>
                <Text
                    style={tw`text-white font-[550] ${title.length > 12 ? 'text-[20px]' : 'text-[28px]'} tracking-[0.6px] mb-[11px] ml-[25px] mt-[16px]`}
                >
                   {title}
                </Text>
                <Text style={tw`text-gray-400 text-[21px] font-[300] ml-[25px] mb-[12px]`}>
                    {multiDayLessThanWeek ? (
                        `${startDay}, ${startTime} - ${endDay}, ${endTime}`
                    ) : (
                        `${startDateTime.toFormat("t")} - ${endDateTime.toFormat("t")}`
                    )}
                </Text>
            </View>
            <View style={tw`flex flex-col w-[36%] items-end`}>
                <Text style={tw`text-white text-[40px] font-[300] mt-[15px] mb-[2px]`}>
                    {moreThanDay ? `${startDate}-${endDate}` : startDay}
                </Text>
                <Text style={tw`text-white text-[22px] font-[300] mb-[10px]`}>
                    {moreThanDay 
                        ? (moreThanMonth ? `${startMonth}-${endMonth}` : startMonth)
                        : `${startMonth} ${startDate}`
                    }
                </Text>
            </View>
        </View>
    );
};

export default TitleRow;
