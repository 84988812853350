import React, { useContext } from "react";
import { FlatList, View, Text, SafeAreaView, Dimensions } from "react-native";
import tw from "twrnc";
import PropTypes from "prop-types";

import EmptyRow from "../../../../components/EmptyRow";
import EventRow from "../../components/EventRow/EventRow";

import { Calendar } from "../../../../utils/images";
import { EventContext } from "../../EventContext";
import { EditIcon } from "../../../../utils/icons";

interface EventProps {
  data: any[];
  navigate: Function;
  setCurrEventId: Function;
  users: Record<string, any>;
  userId: string;
  route: string;
  upcoming?: boolean;
  recurring?: boolean;
  onRefresh?: () => void;
  onEndReached?: () => void;
}

const renderItem = (
  item: any,
  navigate: Function,
  setCurrEventId: Function,
  readEvent: Function,
  users: Record<string, any>,
  userId: string,
  route: string,
  upcoming?: boolean,
  recurring?: boolean
) => {
  let eventGuests = {};
  if (route === "EventsFeed") {
    const eventInvited: any[] = [];
    const eventGoing: any[] = [];
    let eventInvitedContacts: any[] = [];

    if (item.eventInvitedUsers) {
      const eventInvitedIds = item.eventInvitedUsers;
      const eventGoingIds = Array.from(new Set([...item.eventHosts, ...item.eventGoingUsers]));

      eventInvitedIds.forEach((id) => {
        const user = users[id];
        if (user !== undefined && !eventGoingIds.includes(id)) {
          eventInvited.push(user);
        }
      });

      eventGoingIds.forEach((id) => {
        const user = users[id];
        if (user !== undefined) {
          eventGoing.push(user);
        }
      });

      if (item.eventContacts) {
        const formedEventContacts = item.eventContacts.map((contact: any) => ({
          userFirst: contact.givenName,
          userLast: contact.familyName || "",
          userMedia: [],
          id: null,
          userMeets: [],
        }));

        eventInvitedContacts = formedEventContacts;
      }

      eventGoing.reverse();

      eventGuests = {
        invitedUsers: eventInvited,
        goingUsers: eventGoing,
        invitedContacts: eventInvitedContacts,
      };
    }
  }

  let invitedBy = null;
  if (item.eventInvites && item.eventInvites[userId]) {
    invitedBy = users[item.eventInvites[userId]];
  } else if (item.venueInvites && item.venueInvites[userId]) {
    invitedBy = users[item.venueInvites[userId]];
  }


  return (
    <View style={tw`flex-1 px-4`}>
      <EventRow
        userId={userId}
        invitedBy={invitedBy}
        image={item.eventMedia[0]}
        title={item.eventTitle}
        time={item.eventStart}
        navigate={navigate}
        eventGuests={eventGuests}
        eventHosts={item.eventHosts}
        hideGuests={item.eventHideGuests}
        gradientIndex={item.eventGradientIndex}
        venue=""
        icon={upcoming ? EditIcon : null}
        iconStyle={tw`self-end mt-2.5 mr-2.5`}
        onPressIcon={() => {
          setCurrEventId(item.id);
          readEvent(item.id, navigate, "/event/edit");
        }}
        iconSize={30}
        onPress={() => {
          if (recurring) {
            navigate(`/recurring_event?eventId=${item.id}`);
          } else {
            setCurrEventId(item.id);
            readEvent(item.id, navigate);
          }
        }}
        dayOfWeek={item.dayOfWeek}
        recurring={recurring}
      />
    </View>
  );
};

const renderEmpty = () => (
  <View style={tw`flex-1 items-center justify-center`}>
    <EmptyRow icon={Calendar} title="No Events" />
    <Text style={tw`text-lg text-white opacity-20 text-center mt-2`}>
      Seems a little quiet right now. Create your own event, search a code,
      discover nearby venues, or check your friends' statuses to see what
      they're up to tonight!
    </Text>
  </View>
);

const keyExtractor = (item: any) => item.id;

const EventsListView: React.FC<EventProps> = ({
  data,
  navigate,
  setCurrEventId,
  users,
  userId,
  route,
  upcoming,
  recurring,
  onRefresh = () => {},
  onEndReached,
}) => {
  const { readEvent } = useContext(EventContext);

  // Determine number of columns based on screen width
  const { width } = Dimensions.get("window");
  const numColumns = width > 800 ? 2 : 1; // Adjust this breakpoint as needed


  return (
    <SafeAreaView style={tw`flex-1 bg-transparent`}>
      <FlatList
        style={tw`flex-1`}
        contentContainerStyle={tw`items-center pt-1.5`}
        data={data}
        removeClippedSubviews={true}
        initialNumToRender={10}
        renderItem={({ item }) =>
          renderItem(item, navigate, setCurrEventId, readEvent, users, userId, route, upcoming, recurring)
        }
        keyExtractor={keyExtractor}
        ListEmptyComponent={renderEmpty}
        onRefresh={onRefresh}
        onEndReached={onEndReached}
        onEndReachedThreshold={0.3}
        numColumns={numColumns}
        columnWrapperStyle={numColumns > 1 ? tw`px-4` : undefined}
        refreshing={false}
      />
    </SafeAreaView>
  );
};

EventsListView.propTypes = {
  data: PropTypes.array.isRequired,
  navigate: PropTypes.func.isRequired,
  setCurrEventId: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  upcoming: PropTypes.bool,
  recurring: PropTypes.bool,
  onRefresh: PropTypes.func,
  onEndReached: PropTypes.func,
};

export default EventsListView;
