import React from "react";
import PropTypes from "prop-types";
import { TouchableOpacity, Text } from "react-native";
import IconButton from "../../../../components/IconButton";
import { CopyIcon } from "../../../../utils/icons";
import style from "./style";


const PhoneRow = (props) => (
    <TouchableOpacity 
    onPress={() => {}}
    style={style.container}
    >
        <Text style={style.icon}> 📱 </Text>
        <Text style={style.text}>{props.venuePhoneNumber}</Text>
        <IconButton
        icon={CopyIcon}
        theme={"dark"}
        size={25}
        style={style.item}
        onPress={() => {}}
      />
    </TouchableOpacity>
);

PhoneRow.propTypes = {
    venuePhoneNumber: PropTypes.string.isRequired,
};

export default React.memo(PhoneRow);
