import { StyleSheet } from "react-native";

import { ash, charcoal, white } from "../../utils/color";
import { family, title } from "../../utils/font";

export default StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    width: "100%",
    height: 56,
  },

  field: {
    justifyContent: "center",
    alignItems: "center",

    minHeight: 57,
  },

  line: {
    width: "100%",
    height: 1,
    backgroundColor: "white"

  },

  lineDisabled: {
  },

  picker: {
    width: "100%",
    color: "red"
  },

  text: {
    flex: 1,
    margin: 16,

    fontFamily: family,
    fontSize: title,
    color: "white"
  },

  textDisabled: {
    color: ash,
  },
});