import React, { useState, useEffect, useContext } from "react";
import {
  Keyboard,
  SafeAreaView,
  TouchableWithoutFeedback,
  View,
  Text,
} from "react-native";
import tw from "twrnc";
import BubbleButton from "../../components/BubbleButton";
import BubbleTextField from "../../components/BubbleTextField";
import RatioImage from "../../components/RatioImage";
import { AvenuArrow, AvenuNameNew } from "./images";
import { color, darkThemeBackground } from "../../utils/color";
import { useScreen } from "../../hooks/useScreen";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import { UiContext } from "../../uiContext";
import { EventContext } from "../events/EventContext";
import { VenueContext } from "../venues/VenueContext";

const AuthPasswordView = () => {
  const [password, onChangePassword] = useState<string>("");
  const { width } = useScreen();

  const navigate = useNavigate();
  const {
    user,
    userFound,
    userAuth,
    loginEmail,
    readUserIfPresent,
    onLoginCallbackObj,
    error,
  } = useContext(AuthContext);
  const { url, setUrl } = useContext(UiContext);
  const { event, buyFreeTicket, updateGoingUsers } = useContext(EventContext);
  const { venue, updateVenueGoingList } = useContext(VenueContext);

  useEffect(() => {
    console.log("DEBUG userAUth", userAuth);
    if (userAuth && Object.keys(userAuth).length !== 0) {
      readUserIfPresent(userAuth.user.uid);
    }
  }, [userAuth]);

  useEffect(() => {
    if (userFound === true && user) {
      console.log("DEBUG userfound..?", user, "userAUth:", userAuth);
      if (!onLoginCallbackObj) {
        navigate(-3);
        return;
      }
      if (onLoginCallbackObj && onLoginCallbackObj["func"] == buyFreeTicket) {
        onLoginCallbackObj["func"](userAuth.user.uid, event);
        console.log("DEBUG fired onLoginCallbackObj");
        navigate(url);
        setUrl(null);
      } else if (
        onLoginCallbackObj &&
        onLoginCallbackObj["updateGoingUsers"] == updateGoingUsers
      ) {
        onLoginCallbackObj["updateGoingUsers"](userAuth.user.uid, event);
        onLoginCallbackObj["addEventCodeToUser"](userAuth.user.uid, event);
        console.log("DEBUG fired onLoginCallbackObj");
        navigate(url);
        setUrl(null);
      } else if (
        onLoginCallbackObj &&
        onLoginCallbackObj["name"] == "navToEventTicketBuy"
      ) {
        let navPath = onLoginCallbackObj["func"](
          userAuth.user.uid,
          event,
          onLoginCallbackObj["eventId"],
          user
        );
        console.log("DEBUG navpath fired", navPath);
        navigate(navPath);
      } else if (
        (onLoginCallbackObj && onLoginCallbackObj["name"] == "navToTickets") ||
        onLoginCallbackObj["name"] == "navToCreate" ||
        onLoginCallbackObj["name"] == "navToHost"
      ) {
        let navPath = onLoginCallbackObj["func"];
        console.log("navToName fired", navPath);
        navigate(navPath());
      } else if (onLoginCallbackObj["name"] == "navToVenueDetail") {
        updateVenueGoingList(userAuth.user.uid, venue);
        let navPath = onLoginCallbackObj["func"];
        console.log("navToName fired", navPath);
        navigate(navPath());
      } else if (onLoginCallbackObj.name === "navToEdit") {
        let navPath = onLoginCallbackObj.func;
        console.log("navToEdit fired", navPath);
        navigate(navPath().route, { state: navPath().params });
      } else {
        navigate(-3);
      }
    } else if (userFound === false) {
      navigate("/auth/signup");
    } else {
      console.log(
        "DEBUG this code should not run... except when resetting",
        userFound,
        user
      );
    }
    console.log("DEBUG user fired for some reason", user);
  }, [userFound]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <SafeAreaView
        style={[
          tw`flex-1 justify-start items-center bg-[${darkThemeBackground}]`,
          { height: "100vh" },
        ]}
      >
        <View style={tw`m-8 mb-[200px] rounded-[10px] shadow-xl`}>
          <RatioImage image={AvenuNameNew} size={70} ratio={3.64} />
        </View>
        <BubbleTextField
          style={{ width: width * 0.85 }}
          text={password}
          type={"password"}
          placeholder="Enter Password"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChangePassword(event.target.value)
          }
          onSubmit={() => {
            loginEmail(password);
          }}
        />
        <BubbleButton
          title={"Login"}
          onPress={() => {
            loginEmail(password);
          }}
          bubbleStyle={[tw`shadow-xl bg-[${color}]`, { width: width * 0.85 }]}
          titleStyle={tw`text-black`}
        />
        {error && (
          <Text style={tw`text-white text-center my-[5px] mx-6`}>
            Invalid phone number / verification password combination Please try
            again or contact support@avenuteam.com
          </Text>
        )}
        <RatioImage
          image={AvenuArrow}
          size={75}
          ratio={1}
          style={tw`m-8 tint-white`}
        />
      </SafeAreaView>
    </TouchableWithoutFeedback>
  );
};

export default AuthPasswordView;
