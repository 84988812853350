import React from "react";
import { View, Text, Share, TouchableOpacity } from "react-native";
import PropTypes from "prop-types";

import IconButton from "../../../../components/IconButton";
import style from "./style";

import { CopyIcon } from "../../../../utils/icons";

const EventRefRow = (props) => (
    <TouchableOpacity
        onPress={() => navigator.clipboard.writeText(`${props.refCode}`)}
        style={style.container}
        >
        <View style={style.refRow}>
                <img src={require("../../../../assets/icons/icon-eventcode.png")} style={{...style.image, width: '30px', height: '30px', marginLeft: '20px'}} /> 
                <Text style={style.text}> {props.refCode}</Text>
            <View style={style.buttons}>
                <IconButton
                    style={style.item}
                    icon={CopyIcon}
                    theme={"dark"}
                    size={26}
                    onPress={() => { }}
                />
            </View>
        </View>
    </TouchableOpacity>
);

EventRefRow.propTypes = {
    refCode: PropTypes.string.isRequired,
};

export default React.memo(EventRefRow);
