import React from "react";
import { View } from "react-native";
import PropTypes from "prop-types";

import style from "./style";

const BubbleTextField = (props) => {
    const handleKeyDown = (event) => {
        if (event.code === "Enter") {
            console.log("handle key down Enter");
            if (props.onSubmit) {
                props.onSubmit();
            }
        }
    };

    return (
        <View style={[style.bubble, props.style]}>
            <input
                type={props.type ? props.type : "text"}
                inputMode={props.inputMode}
                pattern={props.pattern}
                style={style.textfield}
                placeholder={props.placeholder}
                value={props.text}
                onChange={props.onChange}
                onKeyDown={handleKeyDown}
            />
        </View>
    );
};

BubbleTextField.defaultProps = {
    onSubmit: () => {},
    style: {},
    pattern: undefined,
    inputMode: undefined,
    type: "text"
};

BubbleTextField.propTypes = {
    text: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    keyboardType: PropTypes.string,
    style: PropTypes.object,
    pattern: PropTypes.string,
    inputMode: PropTypes.string,
    type: PropTypes.string
};

export default React.memo(BubbleTextField);
