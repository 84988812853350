import React from "react";
import { Text, SafeAreaView, View } from "react-native";
import { useNavigate } from "react-router-dom";
import tw from "twrnc";
import BubbleButton from "../../components/BubbleButton";
import RatioImage from "../../components/RatioImage";
import TextButton from "../../components/TextButton";
import { AvenuArrow, AvenuNameNew } from "./images";
import { darkThemeBackground, color } from "../../utils/color";
import { useScreen } from "../../hooks/useScreen";

const AuthHomeScreen: React.FC = () => {
    const navigate = useNavigate();
    const { width } = useScreen();
    
    return (
        <SafeAreaView style={[
            tw`flex-1 justify-start items-center h-screen`,
            { backgroundColor: darkThemeBackground, minHeight: "100vh" }
        ]}>
            <View style={tw`m-8 mb-[150px] rounded-lg shadow-lg shadow-black/60`}>
                <RatioImage image={AvenuNameNew} size={70} ratio={3.64} />
            </View>
            <BubbleButton
                title={"Login or Sign Up with Phone"}
                onPress={() => navigate("/auth/phone")}
                bubbleStyle={tw.style("h-[70px] rounded-[40px] shadow-lg shadow-black/60", {
                    width: width * 0.9
                })}
                color={"#8ADDFF"}
                titleStyle={tw`text-black text-[19px]`}
            />
            <Text style={tw`text-white text-[20px] text-center my-4`}>Or</Text>
            <BubbleButton
                title={"Organization Login or Sign Up"}
                onPress={() => navigate("/auth/email")}
                bubbleStyle={tw.style("h-[70px] rounded-[40px] shadow-lg shadow-black/60 scale-85", {
                    width: width * 0.9,
                    backgroundColor: color
                })}
                titleStyle={tw`text-black text-[19px]`}
            />
            <RatioImage
                image={AvenuArrow}
                size={75}
                ratio={1}
                style={tw`m-5 tint-white`}
            />
            <TextButton
                text="By signing in, you agree to Avenu's Terms of Service & Privacy Policy"
            onPress={() => navigate("/auth/legal")}
            />
        </SafeAreaView>
    );
};

export default AuthHomeScreen;
