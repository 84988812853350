import React from "react";
import { Modal, TouchableOpacity } from "react-native";

import style from "./style";

interface PopupModalProps {
    displayState: boolean;
    setDisplayState: (display: boolean) => void;
    theme: string;
    children: React.ReactNode;
}

const PopupModal: React.FC<PopupModalProps> = ({ 
    displayState = false,
    setDisplayState,
    theme,
    children
}) => (
    <Modal transparent={true} visible={displayState}>
        <TouchableOpacity
            style={[style.overlay, theme === "dark" && style.overlayDark]}
            onPress={() => setDisplayState(false)}
        >
            <TouchableOpacity
                style={[style.modal, theme === "dark" && style.modalDark]}
                activeOpacity={1}
            >
                {children}
            </TouchableOpacity>
        </TouchableOpacity>
    </Modal>
);

export default React.memo(PopupModal);