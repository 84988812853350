import React, { useState, useEffect, useContext } from "react";
import tw from "twrnc";
import { SafeAreaView, View, Text } from "react-native";
import BubbleButton from "../../components/BubbleButton";
import LabeledBarTextField from "../../components/LabeledBarTextField";
import ProfileEditImage from "../../components/ProfileEditImage";
import { sky } from "../../utils/color";
import { AuthContext } from "../auth/AuthContext";
import { UiContext } from "../../uiContext";
import { EventContext } from "../events/EventContext";
import { useNavigate } from "react-router-dom";

const RegistrationScreen = () => {
  const [firstName, onChangeFirstName] = useState<string>("");
  const [lastName, onChangeLastName] = useState<string>("");
  const [birthdateString, setBirthdateString] = useState<string>("");
  const [birthdate, setBirthDate] = useState<Date | null>(null);
  const [media, setMedia] = useState<string[]>([]);
  const [school, setSchool] = useState<string>("");
  const [invitedBy, setInvitedBy] = useState("");

  const { url, setUrl } = useContext(UiContext);
  const { event, updateGoingUsers, buyFreeTicket } = useContext(EventContext);
  const navigate = useNavigate();
  const {
    user,
    userFound,
    userAuth,
    createUser,
    onLoginCallbackObj,
    readUserIfPresent,
  } = useContext(AuthContext);

  const firstNameCharLimit = 30;
  const lastNameCharLimit = 30;
  const invitedByCharLimit = 30;
  const schoolCharLimit = 50;

  useEffect(() => {
    console.log("DEBUG userAUth", userAuth);
    if (userAuth && Object.keys(userAuth).length !== 0) {
      readUserIfPresent(userAuth.user.uid);
    }
  }, [userAuth]);

  useEffect(() => {
    if (userFound === true) {
      console.log("DEBUG user fired");
      if (onLoginCallbackObj && onLoginCallbackObj["func"] === buyFreeTicket) {
        onLoginCallbackObj["func"](userAuth.user.uid, event);
        navigate(url);
        setUrl(null);
      } else if (
        onLoginCallbackObj &&
        onLoginCallbackObj["updateGoingUsers"] === updateGoingUsers
      ) {
        onLoginCallbackObj["updateGoingUsers"](userAuth.user.uid, event);
        onLoginCallbackObj["addEventCodeToUser"](userAuth.user.uid, event);
        navigate(url);
        setUrl(null);
      } else if (
        onLoginCallbackObj &&
        onLoginCallbackObj["name"] === "navToEventTicketBuy"
      ) {
        let navPath = onLoginCallbackObj["func"](
          userAuth.user.uid,
          event,
          onLoginCallbackObj["eventId"],
          user
        );
        navigate(navPath);
      } else if (
        onLoginCallbackObj &&
        (onLoginCallbackObj["name"] === "navToTickets" ||
          onLoginCallbackObj["name"] === "navToCreate" ||
          onLoginCallbackObj["name"] === "navToHost")
      ) {
        let navPath = onLoginCallbackObj["func"]();
        navigate(navPath);
      } else {
        navigate(-3);
      }
    } else {
      console.log(
        "DEBUG this code should not run... except when resetting",
        "userFound:",
        userFound,
        "user:",
        user,
        "userAuth:",
        userAuth
      );
    }
  }, [userFound]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const profile = {
    userFirst: firstName,
    userLast: lastName,
    userSearch: `${firstName.toLowerCase()} ${lastName.toLowerCase()}`,
    userHandle: "",
    userPhone: userAuth?.user?.phoneNumber,
    userEmail: userAuth?.user?.email ?? "",
    userBio: "",
    userBirthdate: birthdate,
    userGender: userAuth?.user?.email ? "Prefer not to say" : "",
    userSchool: school,
    userMedia: media,
    userInvitedBy: invitedBy,
    userStatus: { statusType: "NOT SURE", updatedAt: new Date() },
    userFriends: [],
    userMeets: [],
    userRequests: [],
    userRequestsMeet: [],
    userBlocks: [],
    userTokens: [],
    userPrivacy: true,
    userWork: "",
    userInstagram: "",
  };

  const isValidEmail = (fields: any) => {
    return (
      fields.userFirst !== "" &&
      fields.userLast !== "" &&
      fields.userMedia.length !== 0 &&
      !isNaN(fields.userBirthdate)
    );
  };

  const handleChangeBirthdateString = (birthdateString: string) => {
    const formattedText = formatBirthdate(birthdateString);
    setBirthdateString(formattedText);
    if (birthdateString.length !== 10 && birthdateString.length !== 8) {
      setBirthDate(null);
    } else {
      const date = new Date(birthdateString);
      setBirthDate(date);
    }
  };

  const formatBirthdate = (input: string): string => {
    let formattedText = input.replace(/[^0-9]/g, "");

    if (formattedText.length > 2 && formattedText.length <= 4) {
      formattedText = formattedText.slice(0, 2) + "/" + formattedText.slice(2);
    } else if (formattedText.length > 4) {
      formattedText =
        formattedText.slice(0, 2) +
        "/" +
        formattedText.slice(2, 4) +
        "/" +
        formattedText.slice(4, 8);
    }

    return formattedText;
  };

  const handleEnter = () => {
    if (isValidEmail(profile)) {
      createUser(userAuth.user.uid, profile);
    }
  };

  return (
    <SafeAreaView style={tw`flex-1 bg-[#1A1A1A] h-full min-h-[100vh]`}>
      <View
        style={[
          tw`flex-1 flex flex-col justify-between w-full items-center bg-[#1A1A1A] h-full min-h-[100vh] py-10 px-8`,
          { minHeight: "100vh" },
        ]}
      >
        <View>
          <ProfileEditImage image={media[0]} onPress={setMedia} />
          {userAuth?.user.phoneNumber ? (
            <Text style={tw`w-[90%] self-center text-center text-white`}>
              Show off your fabulous face for secure, hassle-free event entry!
              😁
            </Text>
          ) : null}
        </View>
        {media.length !== 0 ? (
          <View style={tw`flex-1 w-full bg-[#1A1A1A] h-full`}>
            <LabeledBarTextField
              label={
                userAuth?.user.phoneNumber ? "First Name" : "Organization Name"
              }
              text={firstName}
              required={true}
              placeholder={
                userAuth.user.phoneNumber
                  ? "Enter Your First Name"
                  : "Enter Your Organization Name"
              }
              charLimit={firstNameCharLimit}
              rightLabel={`${firstName.length}/${firstNameCharLimit}`}
              onChange={onChangeFirstName}
              onSubmit={handleEnter}
              style={tw`m-0 bg-[#2A2A2A]`}
            />
            <LabeledBarTextField
              label={userAuth.user.phoneNumber ? "Last Name" : "City or Campus"}
              text={lastName}
              required={true}
              placeholder={
                userAuth.user.phoneNumber
                  ? "Enter Your Last Name"
                  : "Enter Your City or Campus"
              }
              charLimit={lastNameCharLimit}
              rightLabel={`${lastName.length}/${lastNameCharLimit}`}
              onChange={onChangeLastName}
              onSubmit={handleEnter}
              style={tw`m-0 bg-[#2A2A2A]`}
            />
            {userAuth.user.phoneNumber ? (
              <>
                <LabeledBarTextField
                  label="Birthdate"
                  text={birthdateString}
                  placeholder="MM/DD/YYYY"
                  keyboardType="numeric"
                  required={true}
                  onChange={handleChangeBirthdateString}
                  onSubmit={handleEnter}
                  style={tw`m-0 bg-[#2A2A2A]`}
                />
                <LabeledBarTextField
                  label="School"
                  text={school}
                  required={false}
                  placeholder="Enter Your School"
                  charLimit={schoolCharLimit}
                  leftLabel=" (optional)"
                  rightLabel={`${school.length}/${schoolCharLimit}`}
                  onChange={setSchool}
                  onSubmit={handleEnter}
                  style={tw`m-0 bg-[#2A2A2A]`}
                />
                <LabeledBarTextField
                  label={"Invited By"}
                  text={invitedBy}
                  required={false}
                  placeholder={"First & Last Name"}
                  charLimit={invitedByCharLimit}
                  leftLabel=" (optional)"
                  rightLabel={`${invitedBy.length}/${invitedByCharLimit}`}
                  onChange={setInvitedBy}
                  onSubmit={handleEnter}
                  style={tw`m-0 bg-[#2A2A2A]`}
                />
              </>
            ) : null}
            <View style={tw`mt-10`} />
          </View>
        ) : null}
        <BubbleButton
          title="Confirm"
          onPress={handleEnter}
          buttonStyle={tw`fixed bottom-2.5 w-full`}
          bubbleStyle={tw`mt-6 mb-6 bg-[${sky}]`}
          titleStyle={tw`text-black`}
          disabled={!isValidEmail(profile)}
          color={sky}
        />
      </View>
    </SafeAreaView>
  );
};

export default RegistrationScreen;
