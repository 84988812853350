import React from "react";
import { View, Text } from "react-native";
import DisabledSwitch from "./DisabledSwitch";
import { charcoal, white, black } from "../utils/color";
import tw from "twrnc";

interface TooltipProps {
  theme: "dark" | "light";
  header: string;
  text: string;
}

const Tooltip: React.FC<TooltipProps> = ({ theme, header, text }) => {
  const backgroundColor = theme === "dark" ? charcoal : white;
  const textColor = theme === "dark" ? white : black;

  return (
    <View style={[{ backgroundColor }]}>
      <Text style={[tw`text-base font-semibold mb-2`, { color: textColor }]}>
        {header}
      </Text>
      <Text style={[tw`text-sm leading-5`, { color: textColor }]}>{text}</Text>
      <View style={tw`mt-6`}>
        <DisabledSwitch />
      </View>
    </View>
  );
};

export default React.memo(Tooltip);
