import { StyleSheet } from "react-native";

import { ash, charcoal, darkThemeBackground, red, snow } from "../../utils/color";
import { family, title } from "../../utils/font";

export default StyleSheet.create({
  container: {
  
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderRadius: 0,
    width: "100%"
  },

  field: {
    justifyContent: "center",
    alignItems: "center",
   
  },

  lineDisabled: {
    backgroundColor: ash,
  },

  lineError: {
    backgroundColor: red,
  },

  text: {
    flex: 1,
    margin: 15,

    fontFamily: family,
    fontSize: title,
    backgroundColor: darkThemeBackground,
    border: "0px",

    color: snow,
    width: "15rem + 200vh",
  },

  textError: {
    color: red,
  },
});