import React, { useRef, useState } from "react";
import { Dimensions, FlatList, SectionList, View, SafeAreaView, Text } from "react-native";

import PropTypes from "prop-types";

// import RBSheet from "react-native-raw-bottom-sheet";
import { Modal } from "@mui/material";
import {Box} from "@mui/material";
import CircleButton from "../../../../../components/CircleButton";

import ItemRow from "../../../../../components/ItemRow";
import TextIconField from "../../../../../components/TextIconField";
import NavHeader from "../../../../../components/NavHeader/NavHeader";

import { red } from "../../../../../utils/color";
import { CancelIcon, MoreIcon, PlusIcon, SearchIcon } from "../../../../../utils/icons";
import { Check } from "../../../../../utils/images";

import style from "./style";

const renderItem = (
  theme,
  item,
  personnel,
  setPersonnel,
  artists,
  setArtists,
  scanners,
  setScanners,
) => (
  <ItemRow
    theme={"dark"}
    title={`${item.userFirst} ${item.userLast}`}
    image={item.userMedia[0]}
    icons={[
      { icon: MoreIcon, onPress: () => {} },
      {
        icon: CancelIcon,
        tintColor: red,
        onPress: () => {
          if (personnel.includes(item)) {
            const index = personnel.indexOf(item);
            personnel.splice(index, 1);
            setPersonnel([...personnel]);
          } else if (artists.includes(item)) {
            const index = artists.indexOf(item);
            artists.splice(index, 1);
            setArtists([...artists]);
          } else {
            const index = scanners.indexOf(item);
            scanners.splice(index, 1);
            setScanners([...scanners]);
          }
        },
      },
    ]}
    onPress={() => {}}
  />
);

const renderSearchItem = (item, personnel, setPersonnel, theme) => {
  const addFriend = () => {
    setPersonnel([...personnel, item]);
  };
  const removeFriend = () => {
    const index = personnel.indexOf(item);
    personnel.splice(index, 1);
    setPersonnel([...personnel]);
  };

  const addFriendIcon = {
    icon: PlusIcon,
    tintColor: "white",
    onPress: addFriend,
  };
  const removeFriendIcon = {
    icon: CancelIcon,
    tintColor: red,
    onPress: removeFriend,
  };

  return (
    <ItemRow
      theme={theme}
      title={`${item.userFirst} ${item.userLast}`}
      image={item.userMedia[0]}
      icons={[personnel.includes(item) ? removeFriendIcon : addFriendIcon]}
      onPress={personnel.includes(item) ? removeFriend : addFriend}
      width={"60vw"}
    />
  );
};

const renderEmpty = () => <></>;

const keyExtractor = (item) => item.id;

const EventPersonnelView = (props) => {
  const [personnel, setPersonnel] = useState(props.personnel);
  const [artists, setArtists] = useState(props.artists);
  const [ticketScanners, setTicketScanners] = useState(props.ticketScanners);
  const [search, onChangeSearch] = useState("");
  const [focused, setFocused] = useState("");

  const [hostOpen, setHostOpen] = useState(false);
  const [artistOpen, setArtistOpen] = useState(false);
  const [scannerOpen, setScannerOpen] = useState(false);

  const data = [
    {
      title: "Add Friends as Co-Hosts",
      data: personnel.filter((user) => user.id !== props.userId),
    },
    {
      title: "Add Artists and Performers",
      data: artists,
    },
    {
      title: "Add Ticket Scanners",
      data: ticketScanners,
    },
  ];

  return (
    <SafeAreaView style={style.screen}>
        <NavHeader
                onPress={() => {
                    props.navigate(-1);
                }}
            />
      <View style={style.container}>
        <Text style={style.header}>Manage personnel</Text>
        <Text style={style.subtitle}>Host</Text>
        <ItemRow
          title={`${props.user.userFirst} ${props.user.userLast}`}
          image={props.user.userMedia[0]}
          icons={[]}
        />
        <SectionList
          sections={data}
          renderItem={({ item }) =>
            renderItem(
              props.theme,
              item,
              personnel,
              setPersonnel,
              artists,
              setArtists,
              ticketScanners,
              setTicketScanners
            )
          }
          renderSectionHeader={({ section: { title } }) => (
            <ItemRow
              theme={"dark"}
              title={title}
              background={true}
              hideImage={true}
              icons={
                title === " "
                  ? []
                  : [
                      {
                        icon: PlusIcon,
                        tintColor: "white",
                        onPress: () =>
                          title === "Add Friends as Co-Hosts"
                            ? setHostOpen(true)
                            : title === "Add Artists and Performers"
                            ? setArtistOpen(true)
                            : setScannerOpen(true),
                      },
                    ]
              }
              onPress={() =>
                title === "Add Ticket Scanners"
                  ? setScannerOpen(true)
                  : title === "Add Artists and Performers"
                  ? setArtistOpen(true)
                  : setHostOpen(true)
              }
            />
          )}
          ListEmptyComponent={renderEmpty}
          keyExtractor={keyExtractor}
          showsVerticalScrollIndicator={false}
        />
        <Modal
          open={hostOpen}
          onClose={() => setHostOpen(false)}
          style={style.modal}
        >
          <Box style={style.modalCard}>
            <View style={{height: "100%"}}>
            <FlatList
              style={style.list}
              data={props.friends.filter(
                (friend) =>
                  friend.userSearch.startsWith(search.toLowerCase()) &&
                  !ticketScanners.includes(friend)
              )}
              renderItem={({ item }) =>
                renderSearchItem(item, personnel, setPersonnel, props.theme)
              }
              keyExtractor={keyExtractor}
              ListHeaderComponent={
                <View style={{paddingLeft: 20}}>
                  <TextIconField
                  theme={props.theme}
                  value={search}
                  placeholder="Search"
                  onChangeText={onChangeSearch}
                  onBlur={() => setFocused("")}
                  onFocus={() => setFocused("SEARCH")}
                  autoCapitalize="none"
                  focused={focused === "SEARCH"}
                  icon={SearchIcon}
                />
                </View>
              }
              ListEmptyComponent={<Text style={style.empty}>No friends</Text>}
              onRefresh={props.onRefresh}
              refreshing={false}
            />
            <View style={style.sheetFooter}>
              <CircleButton
                icon={Check}
                onPress={() => {
                  setHostOpen(false)
                }}
              />
            </View>

            </View>
          </Box>
        </Modal>
        <Modal 
          open={artistOpen}
          onClose={() => setArtistOpen(false)}
          style={style.modal}
        >
          <Box style={style.modalCard}>
            <View style={{height: "100%"}}>
            <FlatList
              style={style.list}
              data={props.friends.filter(
                (friend) =>
                  friend.userSearch.startsWith(search.toLowerCase()) && !personnel.includes(friend)
              )}
              renderItem={({ item }) => renderSearchItem(item, artists, setArtists, props.theme)}
              keyExtractor={keyExtractor}
              ListHeaderComponent={
                <View style={{paddingLeft: 20}}>
                <TextIconField
                  theme={props.theme}
                  value={search}
                  placeholder="Search"
                  onChangeText={onChangeSearch}
                  onBlur={() => setFocused("")}
                  onFocus={() => setFocused("SEARCH")}
                  autoCapitalize="none"
                  focused={focused === "SEARCH"}
                  icon={SearchIcon}
                />
                </View>
              }
              ListEmptyComponent={<Text style={style.empty}>No friends</Text>}
              onRefresh={props.onRefresh}
              refreshing={false}
            />
            <View style={style.sheetFooter}>
              <CircleButton
                icon={Check}
                onPress={() => {
                  setArtistOpen(false);
                }}
              />
            </View>
            </View>
          </Box>
        </Modal>
        <Modal 
          open={scannerOpen}
          onClose={() => setScannerOpen(false)}
          style={style.modal}
        >
          <Box style={style.modalCard}>
            <View style={{height: "100%"}}>
            <FlatList
              style={style.list}
              data={props.friends.filter(
                (friend) =>
                  friend.userSearch.startsWith(search.toLowerCase()) && !personnel.includes(friend)
              )}
              renderItem={({ item }) =>
                renderSearchItem(item, ticketScanners, setTicketScanners, props.theme)
              }
              keyExtractor={keyExtractor}
              ListHeaderComponent={
                <View style={{paddingLeft: 20}}>
                <TextIconField
                  theme={props.theme}
                  value={search}
                  placeholder="Search"
                  onChangeText={onChangeSearch}
                  onBlur={() => setFocused("")}
                  onFocus={() => setFocused("SEARCH")}
                  autoCapitalize="none"
                  focused={focused === "SEARCH"}
                  icon={SearchIcon}
                />
                </View>
              }
              ListEmptyComponent={<Text style={style.empty}>No friends</Text>}
              onRefresh={props.onRefresh}
              refreshing={false}
            />
            <View style={style.sheetFooter}>
              <CircleButton
                icon={Check}
                onPress={() => {
                  setScannerOpen(false)
                }}
              />
            </View>
            </View>
          </Box>
        </Modal>
        <View style={style.footer}>
          <CircleButton
            icon={Check}
            onPress={() => {
              // if event creator not in personnel, add to personnel
              personnel[0] && personnel[0].id === props.user.id
                ? props.setPersonnel(personnel)
                : props.setPersonnel([props.user, ...personnel]);
              props.setArtists(artists);
              props.setTicketScanners(ticketScanners);
              const temp = props.uiCompleted;
              temp.personnel = true;
              props.setUiCompleted(temp);
              props.navigate(-1);
            }}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

EventPersonnelView.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default EventPersonnelView;
