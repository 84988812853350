import React from "react";
import { TouchableOpacity, View } from "react-native";
import PropTypes from "prop-types";

import style from "./style";
import { black, white } from "../../utils/color";

const getLineStyle = (key, selected, theme) => ({
  ...style.line,
  backgroundColor: theme === "dark" ? white : black,
  ...(key === selected && (theme ? style.lineSelectedDark : style.lineSelected)),
});

const getSectionStyle = (small) => (small ? [style.section, style.sectionSmall] : style.section);

const Section =
  (Comp) =>
  (props) =>
    (
      <View style={style.container}>
        {props.sections.map((section) => (
          <TouchableOpacity
            key={section.key}
            style={getSectionStyle(props.small)}
            onPress={section.onPress}
          >
            <Comp
              section={section}
              selected={props.selected}
              small={props.small}
              theme={props.theme}
            />
            <View
              style={getLineStyle(section.key, props.selected, props.theme)}
            />
          </TouchableOpacity>
        ))}
      </View>
    );

Section.defaultProps = {
  small: false,
  dark: false,
};

Section.propTypes = {
  sections: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  small: PropTypes.bool,
  dark: PropTypes.bool,
};

export default Section;
