import React from "react";
import { View } from "react-native";
import { getTime, getDate } from "../../../../utils/time";
import PropTypes from "prop-types";

import DescriptionRow from "../../components/DescriptionRow/DescriptionRow";
import HeaderImage from "../../components/HeaderImage/HeaderImage";
import HostsRow from "../../components/HostsRow/HostsRow";
import LocationRow from "../../components/LocationRow";
import GuestListRow from "../../components/GuestListRow/GuestListRow";
import LinksRow from "../../components/LinksRow";
import TitleRow from "../../components/TitleRow/TitleRow";
import RecurringRow from "../../components/RecurringRow";
import LoadingRow from "../../components/LoadingRow";

import style from "./style";
import NavHeader from "../../../../components/NavHeader/NavHeader";

const RecurringEventDetailView = (props) => {

  return (
        <View
          style={style.container}
        >
          <NavHeader onPress={() => props.navigate(-1)} />
          <HeaderImage
            image={props.event.eventMedia[0]}
            gradientIndex={props.event.eventGradientIndex}
          />
          <TitleRow
            title={props.event.eventTitle}
            startDate={getDate(props.event.eventStart).split(",")[0]}
            endDate={getDate(props.event.eventEnd).split(",")[0]}
            startTime={getTime(props.event.eventStart)}
            endTime={getTime(props.event.eventEnd)}
            venue="VENUE"
            dayOfWeek={props.event.dayOfWeek}
          />
          <View style={{ marginTop: 10, marginBottom: 10 }} />
          {props.nextEvent ? (
            <RecurringRow
              theme={props.theme}
              startDate={props.nextEvent.eventStart}
              endDate={props.nextEvent.eventEnd}
              dayOfWeek={props.event.dayOfWeek}
              nextEventId={props.event.nextEventId}
              navigation={props.navigation}
            />
          ) : (
            <LoadingRow theme={props.theme} />
          )}
          {!props.event.eventHideGuests || props.event.eventHosts.includes(props.userId) ? (
            <>
              <View style={{ marginTop: 10, marginBottom: 10 }} />
              <GuestListRow
                guests={props.eventGuests}
                navigation={props.navigation}
                theme={props.theme}
                eventId={props.eventId}
              />
            </>
          ) : null}
          <View style={{ marginTop: 10, marginBottom: 10 }} />
          <LocationRow
            theme={props.theme}
            address={props.event.eventLocation.locationAddress}
            eventTitle={props.event.eventTitle}
            coordinates={
              props.event.eventLocation.locationCoordinates ?? {
                latitude: 37.4275,
                longitude: -122.1697,
              }
            }
            initialCoordinates={{
              latitude: 37.77808,
              longitude: -122.41732,
            }}
          />
          <View style={{ marginTop: 10, marginBottom: 10 }} />
          <DescriptionRow description={props.event.eventDescription} theme={props.theme} />
          {!props.event.eventHideHosts || props.event.eventHosts.includes(props.userId) ? (
            <>
              <View style={{ marginTop: 10, marginBottom: 10 }} />
              <HostsRow
                hosts={props.eventHosts}
                navigation={props.navigation}
                theme={props.theme}
              />
            </>
          ) : null}
          { props.event.eventOtherLinks &&  props.event.eventOtherLinks.length > 0 ? (
            <>
              <View style={{ marginTop: 10, marginBottom: 10 }} />
              <LinksRow
                 links={props.event.eventOtherLinks}
               />
            </>
           ): null}
          <View style={{ marginTop: 50, marginBottom: 50 }} />
        </View>
  );
};

RecurringEventDetailView.propTypes = {
  event: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  eventHosts: PropTypes.array.isRequired,
  eventGuests: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  setCurrentEventId: PropTypes.func.isRequired,
  readHostingEvents: PropTypes.func.isRequired,
  deleteRecurringEvent: PropTypes.func.isRequired,
  navigation: PropTypes.object.isRequired,
};

export default RecurringEventDetailView;
