import React from "react";
import { Image, TouchableOpacity } from "react-native";

import style from "./style";
import { darkThemeBackground, white, black } from "../../utils/color";

const getStyle = (size, theme, tintColor, disabled) => {
    const defaultTintColor = theme === "dark" ? white : darkThemeBackground;
    const disabledTintColor = theme === "dark" ? black : white;

    return {
        ...style.icon,
        width: size,
        height: size,
        tintColor: disabled ? disabledTintColor : tintColor || defaultTintColor,
    };
};

const IconImage = (props) => (
    <TouchableOpacity onPress={props.onPress}>
        <Image
            source={props.icon}
            style={[
                getStyle(
                    props.size,
                    props.theme,
                    props.tintColor,
                    props.disabled
                ),
                props.style,
            ]}
        />
    </TouchableOpacity>
);

export default React.memo(IconImage);
