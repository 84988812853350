import React from "react";
import { Text, Image, TouchableOpacity } from "react-native";
import { Modal, Box } from "@mui/material";
import tw from "twrnc";
import IconButton from "./IconButton";
import { Cancel } from "../utils/icons";
import { AppStoreDownload } from "../features/events/utils/images";

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  content: string;
  showAppStoreButton?: boolean;
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  title,
  content,
  showAppStoreButton = false,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={tw`flex  items-center justify-center`}
    >
      <Box style={tw`flex flex-col bg-gray-900 rounded-lg p-6 max-w-md mx-4`}>
        <IconButton
          icon={Cancel}
          tintColor={"white"}
          size={20}
          style={tw`absolute top-2 right-2`}
          onPress={onClose}
        />
        <Text style={tw`text-white text-xl font-bold mb-4`}>{title}</Text>
        <Text
          style={tw`text-white text-base ${showAppStoreButton ? "mb-6" : ""}`}
        >
          {content}
        </Text>
        {showAppStoreButton && (
          <TouchableOpacity
            onPress={() =>
              window.location.replace(
                "https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983"
              )
            }
          >
            <Image source={AppStoreDownload} style={tw`w-32 h-10`} />
          </TouchableOpacity>
        )}
      </Box>
    </Modal>
  );
};

export default CustomModal;
