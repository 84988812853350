import { StyleSheet } from "react-native";
import { charcoal } from "../../utils/color";

export default StyleSheet.create({
    modal: {
        flexDirection: "column",
        backgroundColor: charcoal,
        margin: 50,
        padding: 40,
        borderRadius: 10,
        alignItems: "center",
        alignSelf: "center",
    },

    overlay: {
        backgroundColor: "#000000aa",
        justifyContent: "center",
        flex: 1,
    },

    modalDark: {
        backgroundColor: charcoal,
    },

    overlayDark: {
        backgroundColor: "#000000cc",
    },
});