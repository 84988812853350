import React, { useEffect, useRef } from "react";
import {
    Dimensions,
    TouchableWithoutFeedback,
    View,
    Text,
    Animated,
    Easing,
    Image,
} from "react-native";
import tw from "twrnc";
import { QRCode } from "react-qrcode-logo";
import { DefaultProfileImage } from "../../utils/images";
import { User } from "../../utils/types";

export const AvenuArrowReverse = require("../../assets/images/avenu-arrow-reverse.png");

interface TicketModalProps {
    ticketData: Record<string, any>;
    ticketQuantity?: number;
    ticketType?: string;
    ticketTitle?: string;
    eventRefCode?: string;
    user: User;
    onDismiss: () => void;
}

const TicketModal: React.FC<TicketModalProps> = ({
    ticketData,
    ticketQuantity,
    ticketType,
    ticketTitle,
    eventRefCode,
    user,
    onDismiss,
}) => {
    const colorAnim = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.loop(
            Animated.sequence([
                Animated.timing(colorAnim, {
                    toValue: 3,
                    duration: 2750,
                    easing: Easing.linear,
                    useNativeDriver: false,
                }),
            ])
        ).start();
    }, [colorAnim]);

    const iconColor = colorAnim.interpolate({
        inputRange: [0, 1, 2, 3],
        outputRange: ["#FF0000", "#4285F4", "#8C52FF", "#FF0000"],
    });

    const size = Math.min(500, Dimensions.get("window").width * 0.8);

    return (
        <TouchableWithoutFeedback onPress={onDismiss}>
            <View style={tw`flex-1 justify-center items-center bg-black bg-opacity-25`}>
                <View
                    style={tw`w-11/12 justify-center items-center bg-white rounded-xl p-4 aspect-[0.8]`}
                >
                    {/* QR Code */}
                    <View style={tw`justify-center items-center relative`}>
                        <QRCode
                            size={size}
                            value={JSON.stringify(ticketData)}
                            logoImage={AvenuArrowReverse}
                            logoWidth={48}
                            bgColor="white"
                            logoOpacity={1}
                            removeQrCodeBehindLogo={false}
                        />
                        <Animated.View
                            style={[
                                tw`absolute justify-center items-center w-12 h-12`,
                                { backgroundColor: iconColor as any, borderRadius: 9999 },
                            ]}
                        >
                            <Animated.Image
                                source={AvenuArrowReverse}
                                style={tw`w-12 h-12`}
                            />
                        </Animated.View>
                    </View>

                    {/* User Info */}
                    <View style={tw`flex-row items-center mt-4 self-start`}>
                        <Image
                            source={{
                                uri:
                                    user.userMedia.length > 0
                                        ? user.userMedia[0]
                                        : DefaultProfileImage,
                            }}
                            style={tw`w-10 h-10 rounded-full`}
                        />
                        <Text style={tw`ml-2 text-lg`}>
                            {user.userFirst} {user.userLast}
                        </Text>
                    </View>

                    {/* Ticket Details */}
                    <View style={tw`self-end items-end`}>
                        {ticketQuantity && (
                            <Text style={tw`text-lg`}>
                                {ticketQuantity}x {ticketType}
                            </Text>
                        )}
                        <Text style={tw`text-lg`}>{ticketTitle}</Text>
                        <Text style={tw`text-lg`}>{eventRefCode}</Text>
                    </View>

                </View>
            </View>
        </TouchableWithoutFeedback>
    );
};

export default TicketModal;
