import React from "react";
import { View, Text } from "react-native"
import Linkify from "react-linkify";
import tw from "twrnc";

interface DescriptionRowProps {
    description: string;
}

const DescriptionRow = ({ description }: DescriptionRowProps) => 
    <View style={tw`bg-black rounded-[20px] shadow-lg`}>
        <Linkify>
            <Text style={tw`text-white text-base mt-5 mb-6 mx-5`}>{description}</Text>
        </Linkify>
    </View>;

export default DescriptionRow;
