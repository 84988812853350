import React, { useState } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  View,
  SafeAreaView,
  Text,
} from "react-native";
import PropTypes from "prop-types";
import NavHeader from "../../../../../components/NavHeader/NavHeader";
import CircleButton from "../../../../../components/CircleButton";
import MultiTextField from "../../../../../components/MultiTextField";

import { Check } from "../../../../../utils/images";

import style from "./style";

const EventDescriptionView = (props) => {
  const [description, onChangeDescription] = useState(props.description);
  const [focused, setFocused] = useState("");

  return (
      <SafeAreaView style={style.screen}>
        <NavHeader
                onPress={() => {
                    props.navigate(-1);
                }}
            />
        <View style={style.container}>
            <View style={style.wrapper}>
            <Text style={style.header}>Add a description</Text>
            <View style={style.row}>
              <MultiTextField
                value={description}
                placeholder="Details, web links, dress code, etc."
                onChange={(event) => onChangeDescription(event.target.value)}
                onBlur={() => setFocused("")}
                onFocus={() => setFocused("DESCRIPTION")}
                autoCapitalize="sentences"
                focused={focused === "DESCRIPTION"}
                minRows={2}
                maxRows={15}
              />
            </View>
            </View>
          <View style={style.spacer} />
          <KeyboardAvoidingView
            behavior={Platform.OS === "ios" ? "padding" : "height"}
            keyboardVerticalOffset={44}
          >
            <View style={style.footer}>
              <CircleButton
                icon={Check}
                onPress={() => {
                  props.onChangeDescription(description);
                  props.navigate("/event/create/time");
                }}
                disabled={description === ""}
              />
            </View>
          </KeyboardAvoidingView>
        </View>
      </SafeAreaView>
  );
};

EventDescriptionView.propTypes = {
  description: PropTypes.string.isRequired,
  setDetails: PropTypes.func.isRequired,
  navigation: PropTypes.object.isRequired,
};

export default EventDescriptionView;
