import React, { useState, useEffect } from "react";
import { TouchableOpacity, View, Text } from "react-native";
import PropTypes from "prop-types";

import moment from "moment";
import IconImage from "../../../../components/IconImage";
import { CalendarIcon } from "../../../../utils/icons";

import { getContainerStyle, snow, color } from "../../../../utils/color";

import style from "./style";

const RecurringRow = (props) => {
  const [nextStartDate, setNextStartDate] = useState(null);
  const [followingStartDate, setFollowingStartDate] = useState(null);
  const [inProgress, setInProgress] = useState(false);
//   const [displayRecurringTooltip, setDisplayRecurringTooltip] = useState(false);
//   const [showFollowingEventModal, setShowFollowingEventModal] = useState(false);

  useEffect(() => {
    try {
      let startDate;
      let endDate;
      if (typeof props.startDate.toDate === "function") {
        startDate = moment(props.startDate.toDate());
        endDate = moment(props.endDate.toDate());
      } else {
        startDate = moment(props.startDate);
        endDate = moment(props.endDate);
      }

      if (moment().isAfter(endDate)) {
        const dayOfWeek = moment().day(props.dayOfWeek);
        setInProgress(false);
        if (moment().isAfter(dayOfWeek)) {
          dayOfWeek.add(7, "days");
        }
        setNextStartDate(dayOfWeek.format("MMM D"));
        setFollowingStartDate(dayOfWeek.add(7, "days").format("MMM D"));
      } else {
        if (moment().isAfter(startDate)) {
          setInProgress(true);
        } else {
          setInProgress(false);
        }
        setNextStartDate(startDate.format("MMM D"));
        const followingStartDate = moment(startDate).add(1, "days");
        const targetDayOfWeek = moment().day(props.dayOfWeek).day();
        while (followingStartDate.day() !== targetDayOfWeek) {
          followingStartDate.add(1, "days");
        }
        setFollowingStartDate(followingStartDate.format("MMM D"));
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, []);

  return (
    <View style={getContainerStyle(style.container, props.theme)}>
      <View style={style.wrapper}>
        <View style={style.headerRow}>
          <Text style={style.emoji}>🔁</Text>
          <Text style={style.subtitle}>Recurring Event</Text>
        </View>
        <TouchableOpacity
          onPress={() => props.navigate("EventDetail", { eventId: props.nextEventId })}
          style={style.nextEvent}
        >
          <Text style={{ ...style.weekday, color: snow }}>
            Next Event:
          </Text>
          <Text style={{ ...style.weekday, color }}>{nextStartDate}</Text>
          <IconImage
            theme={props.theme}
            icon={CalendarIcon}
            size={24}
            tintColor={snow}
            style={style.calender}
          />
          {inProgress && <Text style={{ ...style.inProgress }}>In Progress</Text>}
        </TouchableOpacity>
        <TouchableOpacity onPress={() => {}} style={style.nextEvent}>
          <Text style={{ ...style.weekday, color: snow}}>
            Following Event:
          </Text>
          <Text style={{ ...style.weekday, color }}>{followingStartDate}</Text>
          <IconImage
            theme={props.theme}
            icon={CalendarIcon}
            size={24}
            tintColor={snow}
            style={style.calender}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

RecurringRow.propTypes = {
  dayOfWeek: PropTypes.string.isRequired,
};

export default React.memo(RecurringRow);
