import { StyleSheet } from "react-native";

import { snow, ash, charcoal, white, steel } from "../../utils/color";
import { family, body } from "../../utils/font";

export default StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },

  field: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },

  icon: {
    width: 24,
    height: 24,

    tintColor: charcoal,
  },

  iconDisabled: {
    tintColor: ash,
  },

  iconDarkmode: {
    width: 24,
    height: 24,

    tintColor: snow,
  },

  iconDisabledDarkmode: {
    tintColor: steel
  },

  line: {
    width: "100%",
    height: 1,
  },

  text: {
    flex: 1,
    margin: 16,
    height: 57,
    fontFamily: family,
    fontSize: body,
  },
});