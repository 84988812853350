import React, { useEffect, useState } from "react";
import { View, FlatList, Text, Image } from "react-native-web";
import { TouchableOpacity } from "react-native";
import { DefaultProfileImage } from "../../utils/images";
import GuestListAvenuMeetTooltip from "../GuestListAvenuMeetTooltip";
import PopupModal from "../../../../components/PopupModal";
import IconImage from "../../../../components/IconImage";
import { TooltipIcon } from "../../../../utils/icons";
import { User } from "../../../../utils/types";
import tw from "twrnc";
import { APP_STORE_URL } from "../../../../utils/constants";

interface GuestListRowProps {
  eventGuestsUsers: User[];
  eventContacts?: User[];
  theme?: string;
}

const GuestListRow = ({
  eventGuestsUsers,
  eventContacts,
  theme,
}: GuestListRowProps) => {
  const [guestItems, setGuestItems] = useState<User[]>(eventGuestsUsers);
  const [contactItem, setContactItem] = useState<User[] | undefined>(
    eventContacts
  );
  const [displayGuestListTooltip, setDisplayGuestListTooltip] =
    useState<boolean>(false);

  useEffect(() => {
    if (contactItem && guestItems.length + contactItem.length > 50) {
      const slicedGuestItems = guestItems.slice(0, 50);
      slicedGuestItems.push({
        id: "extra",
        text: `+${guestItems.length + contactItem.length - 50}`,
        size: guestItems.length + contactItem.length >= 1000 ? 16 : 20,
        userMedia: [],
      });
      setGuestItems(slicedGuestItems);
    }
  }, []);

  return (
    <View
      style={tw`bg-black rounded-[20px] w-full overflow-hidden shadow-lg shadow-black/60`}
    >
      <View style={tw`flex flex-row items-center w-full`}>
        <Image
          source={require("../../../../assets/icons/icon-avenumeet1.png")}
          style={tw`w-[40px] h-[40px] ml-[10px]`}
        />
        <Text style={tw`text-white text-xl font-bold mb-3 ml-5 mr-4 mt-4`}>
          AvenuMeet
        </Text>
        <TouchableOpacity
          onPress={() => setDisplayGuestListTooltip(true)}
          style={tw`pt-3 pb-[18px]`}
        >
          <IconImage
            theme={theme}
            icon={TooltipIcon}
            size={16}
            tintColor={"white"}
            onPress={() => setDisplayGuestListTooltip(true)}
            style={tw`mt-[3px] mr-[7px]`}
          />
        </TouchableOpacity>
        <Image
          source={require("../../../../assets/icons/icon-avenumeet2.png")}
          style={tw`w-[40px] h-[40px] ml-[10px]`}
        />
      </View>
      <FlatList
        style={tw`ml-4 mb-[10px] mr-[10px]`}
        contentContainerStyle={tw`flex flex-row w-full`}
        horizontal={true}
        data={contactItem ? guestItems.concat(contactItem) : guestItems}
        renderItem={(item) => renderUserProfilePicture(item)}
        showsHorizontalScrollIndicator={false}
      />
      <PopupModal
        displayState={displayGuestListTooltip}
        setDisplayState={setDisplayGuestListTooltip}
        theme={theme}
        children={<GuestListAvenuMeetTooltip theme={theme} venue={false} />}
      />
    </View>
  );
};

const renderUserProfilePicture = (item: { item: User }) => {
  const user = item.item;
  return (
    <TouchableOpacity
      onPress={() => window.location.replace(APP_STORE_URL)}
      style={{
        ...tw`h-[75px] w-[75px] rounded-full border-2 border-white -mr-[7px] flex justify-center items-center`,
        backgroundColor: user.id === "extra" ? "#8C52FF" : user.backgroundColor,
      }}
    >
      {user.id === "extra" ? (
        <Text style={{ ...tw`text-white font-bold`, fontSize: user.size }}>
          {user.text}
        </Text>
      ) : user.id === "contact" ? (
        <Text style={tw`text-white text-2xl font-bold`}>
          {user.givenName?.[0]}
          {user.familyName?.[0]}
        </Text>
      ) : (
        <Image
          key={user.userPhone}
          source={{
            uri: user.userMedia.length
              ? user.userMedia[0]
              : DefaultProfileImage,
          }}
          style={tw`h-[72px] w-[72px] rounded-full`}
        />
      )}
    </TouchableOpacity>
  );
};

export default GuestListRow;
