import React from "react";
import { Image, TouchableOpacity, View } from "react-native";
import PropTypes from "prop-types";

import style from "./style";

const onDisabled = () => {};

const getOnPress = (disabled, onPress) => (disabled ? onDisabled : onPress);
const getOpacity = (disabled) => (disabled ? 1.0 : 0.2);

const getCircleStyle = (disabled) =>
  disabled ? [style.circle, style.circleDisabled] : style.circle;

const getIconStyle = (disabled) => (disabled ? [style.icon, style.iconDisabled] : style.icon);

const CircleButton = (props) => (
  <TouchableOpacity
    onPress={getOnPress(props.disabled, props.onPress)}
    activeOpacity={getOpacity(props.disabled)}
    delayPressIn={0.2}
  >
    <View style={getCircleStyle(props.disabled)}>
      <Image source={props.icon} style={getIconStyle(props.disabled)} />
    </View>
  </TouchableOpacity>
);

CircleButton.defaultProps = {
  disabled: false,
};

CircleButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  icon: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
};

export default React.memo(CircleButton);