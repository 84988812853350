import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    flexDirection: "row",
  },

  line: {
    width: "100%",
    position: "absolute",
    bottom: 0,
  },

  lineSelected: {
    height: 2,
  },

  lineSelectedDark: {
    height: 2,
  },

  section: {
    flexGrow: 1,
    height: 56,
    justifyContent: "center",
    alignItems: "center",
  },

  sectionSmall: {
    flexGrow: 0,
    width: 75,
    height: 44,
  },
});