import { StyleSheet } from "react-native";

import { snow, charcoal } from "../../utils/color";
import { family, body } from "../../utils/font";

export default StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  field: {
    justifyContent: "center",

    height: 57,
  },

  line: {
    width: "100%",
    height: 1,

    //backgroundColor: snow,
  },

  text: {
    flex: 1,
    marginTop: 16,
    marginBottom: 16,
    marginLeft: 20,
    fontFamily: family,
    fontSize: body,

    color: charcoal,
  },
});