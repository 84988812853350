import React, { useState, useEffect, useContext } from "react";
import {
  Keyboard,
  SafeAreaView,
  TouchableWithoutFeedback,
  View,
  Text,
} from "react-native";
import tw from "twrnc";
import BubbleButton from "../../components/BubbleButton";
import BubbleTextField from "../../components/BubbleTextField";
import RatioImage from "../../components/RatioImage";
import { AvenuArrow, AvenuNameNew } from "./images";
import { darkThemeBackground } from "../../utils/color";
import { useScreen } from "../../hooks/useScreen";
import { AuthContext } from "./AuthContext";
import { UiContext } from "../../uiContext";
import { useNavigate } from "react-router-dom";
import { VenueContext } from "../venues/VenueContext";
import { EventContext } from "../events/EventContext";

const AuthCodeScreen: React.FC = () => {
    const navigate = useNavigate();
    const {
        user,
        userFound,
        userAuth,
        login,
        readUserIfPresent,
        onLoginCallbackObj,
        error,
    } = useContext(AuthContext);
    const { url, setUrl } = useContext(UiContext);
    const { event, buyFreeTicket, updateGoingUsers } = useContext(EventContext);
    const { venue, updateVenueGoingList } = useContext(VenueContext);
    const [code, onChangeCode] = useState<string>("");
    const { width } = useScreen();

    useEffect(() => {
        console.log("DEBUG userAUth", userAuth);
        if (userAuth && Object.keys(userAuth).length !== 0) {
            readUserIfPresent(userAuth.user.uid);
        }
    }, [userAuth]);

    useEffect(() => {
        if (userFound === true && user) {
            if (!onLoginCallbackObj) {
                navigate(-3);
                return;
            }

            if (
                onLoginCallbackObj &&
                onLoginCallbackObj["func"] === buyFreeTicket
            ) {
                onLoginCallbackObj["func"](userAuth.user.uid, event);
                navigate(url);
                setUrl(null);
            } else if (
                onLoginCallbackObj &&
                onLoginCallbackObj["updateGoingUsers"] === updateGoingUsers
            ) {
                onLoginCallbackObj["updateGoingUsers"](
                    userAuth.user.uid,
                    event
                );
                onLoginCallbackObj["addEventCodeToUser"](
                    userAuth.user.uid,
                    event
                );
                navigate(url);
                setUrl(null);
            } else if (
                onLoginCallbackObj &&
                onLoginCallbackObj["name"] === "navToEventTicketBuy"
            ) {
                let navPath = onLoginCallbackObj["func"](
                    userAuth.user.uid,
                    event,
                    onLoginCallbackObj["eventId"],
                    user
                );
                navigate(navPath);
            } else if (
                (onLoginCallbackObj &&
                    onLoginCallbackObj["name"] === "navToTickets") ||
                onLoginCallbackObj["name"] === "navToCreate" ||
                onLoginCallbackObj["name"] === "navToHost"
            ) {
                let navPath = onLoginCallbackObj["func"];
                navigate(navPath());
            } else if (
                onLoginCallbackObj &&
                onLoginCallbackObj["name"] === "navToVenueDetail"
            ) {
                updateVenueGoingList(userAuth.user.uid, venue);
                let navPath = onLoginCallbackObj["func"];
                navigate(navPath());
            } else if (
                onLoginCallbackObj &&
                onLoginCallbackObj["name"] === "navToEdit"
            ) {
                let navPath = onLoginCallbackObj.func;
                navigate(navPath().route, { state: navPath().params });
            } else {
                navigate(-3);
            }
        } else if (userFound === false) {
            navigate("/auth/signup");
        } else {
            console.log(
                "DEBUG this code should not run... except when resetting",
                userFound,
                user
            );
        }

        console.log("DEBUG user fired for some reason", user);
    }, [userFound]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <SafeAreaView
                style={[
                    tw`flex-1 justify-start items-center h-screen bg-[${darkThemeBackground}]`,
                    { backgroundColor: darkThemeBackground, minHeight: "100vh" },
                ]}
            >
                <View style={tw`m-8 mb-50 rounded-lg shadow-lg shadow-black/60`}>
                    <RatioImage image={AvenuNameNew} size={70} ratio={3.64} />
                </View>
                <BubbleTextField
                    style={tw`w-[85%] mx-auto`}
                    text={code}
                    placeholder="Enter Code Sent via SMS"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeCode(event.target.value)
                    }
                    onSubmit={() => login(code)}
                    keyboardType="number"
                    pattern="[0-9]*"
                />
                <BubbleButton
                    title="Login"
                    onPress={() => login(code)}
                    bubbleStyle={tw.style("bg-[#8ADDFF] shadow-lg shadow-black/60", {
                        width: width * 0.85,
                    })}
                    color={"#8ADDFF"}
                    titleStyle={tw`text-black`}
                />
                {error && (
                    <Text style={tw`text-white text-center my-1.5 mx-6`}>
                        Invalid phone number / verification code combination Please try
                        again or contact support@avenuteam.com
                    </Text>
                )}
                <RatioImage
                    image={AvenuArrow}
                    size={75}
                    ratio={1}
                    style={tw`mt-12 tint-white`}
                />
            </SafeAreaView>
        </TouchableWithoutFeedback>
    );
};

export default AuthCodeScreen;
