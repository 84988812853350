import { StyleSheet } from "react-native";
import { sky, white } from "../../utils/color";

export default StyleSheet.create({
    component: {
        width: "100%",
        paddingLeft: 24,
        paddingRight: 24,
    },

    container: {
        width: "100%",
        marginTop: 8,
        marginBottom: 8,
    },

    header: {
        flexDirection: "row",
        alignItems: "flex-end",
        marginLeft: 24,
        marginRight: 24,
        marginTop: 16,
        marginBottom: 16,
    },

    label: {
        color: "white",
        fontWeight: "700",
        fontSize: 17,
    },

    asterisk: {
        color: sky,
        fontWeight: "700",
        fontSize: 18,
    },

    left: {
        fontWeight: "300",
        color: white,
    },

    right: {
        marginLeft: "auto",
        fontWeight: "300",
        color: white,
    },
});
