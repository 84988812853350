import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  Text,
  TouchableOpacity,
  View,
  FlatList,
  SafeAreaView,
  Image
} from "react-native";
import PropTypes from "prop-types";

import CircleButton from "../../../../../components/CircleButton";
import NavHeader from "../../../../../components/NavHeader/NavHeader";
import TextIconField from "../../../../../components/TextIconField";

import { firebaseConfig } from "../../../../../firebaseConfig";

import { LocationIcon, MapMarker } from "../../../../../utils/icons";
import { Check } from "../../../../../utils/images";
import GoogleMapReact from "google-map-react";
import { MAPS_API_KEY } from "../../../../../googleMapsConfig";
import style from "./style";
import "./cssStyle.css"

const keyExtractor = (item) => item.place_id;

const EventAddressView = (props) => {
  const [coordinates, setCoordinates] = useState(props.coordinates);
  const [search, setSearch] = useState(props.address ?? "");
  const [focused, setFocused] = useState("");

 const autoCompleteRef = useRef();
 const inputRef = useRef();
 const options = {
  componentRestrictions: { country: "us" },
  fields: ["formatted_address", "geometry", "name"],
 };
 useEffect(() => {
  autoCompleteRef.current = new window.google.maps.places.Autocomplete(
   inputRef.current,
   options
  );

  autoCompleteRef.current.addListener("place_changed", async function() {
    const place = await autoCompleteRef.current.getPlace();
    setSearch(place.formatted_address)
    setCoordinates({latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng()})
  })
 }, []);


  return (
    <SafeAreaView style={style.screen}>
        <NavHeader
          onPress={() => {props.navigate(-1)}}
        />
      <View style={style.container}>
        <Text style={style.header}>Add an address</Text>
        <View style={style.row}>
            <View style={{alignSelf: "center", width: "27vh"}}>
              <TextIconField
                value={search}
                placeholder="Street Address, City, State"
                onChangeText={(text) => {
                  setSearch(text);
                }}
                onBlur={() => setFocused("")}
                onFocus={() => setFocused("ADDRESS")}
                autoCapitalize="words"
                focused={focused === "ADDRESS"}
                icon={LocationIcon}
                setRef={inputRef}
              />
          </View>
           <View style={style.map}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: MAPS_API_KEY }}
                center={{lat: coordinates.latitude, lng: coordinates.longitude}}
                defaultZoom={12}
            >
                <Image 
                style={{width: 32, height: 32}}
                source={MapMarker}
                lat={coordinates.latitude}
                lng={coordinates.longitude}
                />
            </GoogleMapReact>
        </View>
        </View> 
      </View>
      <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          keyboardVerticalOffset={44}
        >
          <View style={style.footer}>
            <CircleButton
              icon={Check}
              onPress={() => {
                props.setAddress(search);
                props.setCoordinates(coordinates);
                props.navigate(-1);
              }}
              disabled={!coordinates.latitude || !coordinates.longitude || !search}
            />
          </View>
        </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

EventAddressView.defaultProps = {
  address: { term: "", fetchPredictions: false },
  coordinates: {},
};

EventAddressView.propTypes = {
  coordinates: PropTypes.object,
};

export default EventAddressView;
