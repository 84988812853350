import React from "react";
import { TouchableOpacity, View, Text } from "react-native";
import PropTypes from "prop-types";

import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import moment from "moment";

import { getDate, getTime } from "../../utils/time";

import style from "./style";


const getLineStyle = (value, focused) =>
  value || focused ? style.line : [style.line, style.lineDisabled];

const getTextStyle = (value) => (value ? style.text : [style.text, style.textDisabled]);

const getTextValue = (value, placeholder, mode) =>
  value ? (mode === "date" ? moment(value).format("MMMM D, YYYY") : moment(value).format("h:mm A")) : placeholder;

const color = "#FFFFFF"
const DateTimeField = (props) => (
  <View style={style.field}>
    <TouchableOpacity onPress={props.setFocused}>
      <View style={style.container}>
        <Text style={getTextStyle(props.value)}>
          {getTextValue(props.value, props.placeholder, props.mode)}
        </Text>
      </View>
    </TouchableOpacity>
    {props.focused && props.mode === "date" && (
      <DatePicker
        value={props.value}
        onChange={(event) => { if (event) props.onChangeValue(event.$d) }}
        minDate={props.minDate}
        maxDate={props.maxDate}
        renderInput={(params) => 
          <TextField 
          sx={{
            svg: { color },
            input: { color },
            label: { color }
          }}
            {...params} 
          />
        }
    />
    )}
    {props.focused && props.mode === "time" && (
      <TimePicker
        value={props.value}
        onChange={(event) => { if (event) props.onChangeValue(event.$d) }}
        minutesStep={15}
        renderInput={(params) => 
          <TextField 
          sx={{
            svg: { color },
            input: { color },
            label: { color }
          }}
            {...params}
          />
        }
      />
    )}
    <View style={getLineStyle(props.value, props.focused)} />
  </View>
);

DateTimeField.defaultProps = {
  value: null,
  minDate: null,
  maxDate: null,
};

DateTimeField.propTypes = {
  value: PropTypes.object,
  placeholder: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  focused: PropTypes.bool.isRequired,
  setFocused: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
};

export default React.memo(DateTimeField);