import React from "react";
import { TouchableOpacity, View, Image } from "react-native";
import { PlusIcon3x } from "../../utils/icons";
import PropTypes from "prop-types";

import style from "./style";

// const isLocal = (image) => {
//   return !image.startsWith("https://");
// };
const getStyle = (size, style) => ({
    ...style,
    width: "80vmin",
    height: "80vmin",
    borderRadius: 10,
    objectFit: "cover",
});

const MediaField = (props) => {
    const hiddenFileInput = React.useRef(null);
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };
    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        console.log("FILE uploaded: ");
        console.log(fileUploaded);
        props.onPress([fileUploaded]);
    };

    return (
        <View style={style.container}>
            <TouchableOpacity style={style.image} onPress={props.onPress}>
                {props.image ? (
                    <img
                        src={
                            props.image && props.image instanceof File
                                ? URL.createObjectURL(props.image)
                                : props.image
                        }
                        style={getStyle(props.size, props.style)}
                    />
                ) : ( props.gradient ? <Image source={props.gradient} style={getStyle(props.size, props.style)}/> :
                    <Image source={PlusIcon3x} style={style.icon} />
                )}
            </TouchableOpacity>
            {/* <input
                ref={hiddenFileInput}
                style={{ display: "none" }}
                type="file"
                name="myimage"
                onChange={handleChange}
            /> */}
        </View>
    );
};

MediaField.defaultProps = {
    image: null,
};

MediaField.propTypes = {
    image: PropTypes.string,
    onPress: PropTypes.func.isRequired,
};

export default React.memo(MediaField);
