import { StyleSheet } from "react-native";

import { ash, charcoal, red } from "../../utils/color";
import { family, title } from "../../utils/font";

export default StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderRadius: 0,
    width: "100%"
  },

  field: {
    justifyContent: "center",
    alignItems: "center",
    height: 57,
  },

  lineDisabled: {
    backgroundColor: ash,
  },

  lineError: {
    backgroundColor: red,
  },

  text: {
    flex: 1,
    margin: 15,

    fontFamily: family,
    fontSize: title,

    color: charcoal,
    width: "15rem + 200vh"
  },

  textError: {
    color: red,
  },
});