import React, { useEffect, useContext, useState, useCallback } from "react";
import { View, Linking, Platform, TouchableOpacity, Text } from "react-native";
import LinearGradient from "react-native-web-linear-gradient";
import tw from "twrnc";
import HeaderImage from "../../components/HeaderImage/HeaderImage";
import TitleRow from "../../components/TitleRow/TitleRow";
import DescriptionRow from "../../components/DescriptionRow/DescriptionRow";
import LocationRow from "../../components/LocationRow";
import TaggedPhotoPreview from "../../components/TaggedPhotoPreview";
import PurchaseRow from "../../components/PurchaseRow";
import NoTicketRow from "../../components/NoTicketRow";
import LinksRow from "../../components/LinksRow";
import HostsRow from "../../components/HostsRow/HostsRow";
import ArtistsRow from "../../components/ArtistsRow";
import GuestListRow from "../../components/GuestListRow/GuestListRow";
import EventRefRow from "../../components/EventRefRow";
import NavHeader from "../../../../components/NavHeader/NavHeader";
import { useNavigate } from "react-router-dom";
import { EventContext } from "../../EventContext";
import { AuthContext } from "../../../auth/AuthContext";
import { UiContext } from "../../../../uiContext";
import ExternalLinkRow from "../../components/ExternalLinkRow";
import { black, charcoal, color } from "../../../../utils/color";
import { useScreen } from "../../../../hooks/useScreen";
import { Event } from "../../../../utils/types";
import CustomModal from "../../../../components/CustomModal";

const EventDetailView: React.FC = () => {
  const [numEventUsers, setNumEventUsers] = useState<number>(0);
  const [isLimitModalOpen, setIsLimitModalOpen] = useState<boolean>(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] =
    useState<boolean>(false);
  const [isPrivateModalOpen, setIsPrivateModalOpen] = useState<boolean>(false);

  const { isMobile } = useScreen();

  const {
    event,
    eventHostsUsers,
    eventArtistsUsers,
    eventGuestsUsers,
    eventContacts,
    buyFreeTicket,
    updateGoingUsers,
    addEventCodeToUser,
  } = useContext(EventContext);

  const { setUrl } = useContext(UiContext);

  const { userAuth, setOnLoginCallbackObj, userFound } =
    useContext(AuthContext);

  const navigate = useNavigate();

  const checkIfAllTicketLimitReached = (event: Event): boolean => {
    try {
      if (!event) return false;

      const eventTickets = event["eventTicketTypes"];

      const isAllLimitReached = eventTickets.every(
        (eventTicket) =>
          eventTicket["ticketLimit"] &&
          eventTicket["ticketsPurchased"] &&
          eventTicket["ticketsPurchased"] >= eventTicket["ticketLimit"]
      );

      return isAllLimitReached;
    } catch (error) {
      console.error("Error in checkIfAllTicketLimitReached:", error);
      return false;
    }
  };

  const handlePurchaseRowClick = useCallback(() => {
    try {
      if (checkIfAllTicketLimitReached(event)) {
        return;
      }
      if (
        userAuth &&
        userAuth.user.uid &&
        event["eventGoingUsers"].includes(userAuth.user.uid)
      ) {
        navigate("/tickets?eventId=" + event.id);
      } else if (numEventUsers >= 9999) {
        setIsLimitModalOpen(true);
      } else if (
        event.eventTicketTypes.length === 1 &&
        event["eventTicketTypes"][0]["ticketPrice"] === 0
      ) {
        if (userAuth && userFound && userAuth.user.uid) {
          buyFreeTicket(userAuth.user.uid, event);
        } else {
          setUrl(window.location.pathname + window.location.search);
          setOnLoginCallbackObj({
            func: buyFreeTicket,
          });
          navigate("/auth/home");
        }
      } else {
        if (userAuth && userFound) {
          navigate("/event/tickets" + window.location.search);
        } else {
          const queryParams = new URLSearchParams(window.location.search);
          const eventId = queryParams.get("eventId");
          setOnLoginCallbackObj({
            func: (
              userId: string,
              event: Event,
              eventId: string,
              user: any
            ) => {
              if (userId && event["eventGoingUsers"].includes(userId)) {
                console.log("DEBUG nav path pased for eventId", eventId);
                return "/event?eventId=" + eventId;
              } else {
                console.log("DEBUG nav path passed for tickets", eventId);
                return "/event/tickets?eventId=" + eventId;
              }
            },
            name: "navToEventTicketBuy",
            eventId: eventId,
          });
          navigate("/auth/home");
        }
      }
    } catch (error) {
      console.error("Error in PurchaseRow eventOnClick:", error);
    }
  }, [
    event,
    userAuth,
    userFound,
    numEventUsers,
    navigate,
    setOnLoginCallbackObj,
    buyFreeTicket,
  ]);

  // Memoize handlers to prevent unnecessary rerenders
  const handleNavHeaderPress = useCallback(() => {
    try {
      if (userAuth && userAuth.user.uid && userFound) {
        navigate("/event/host");
      } else {
        setOnLoginCallbackObj({
          func: () => {
            return "/event/host";
          },
          name: "navToHost",
        });
        navigate("/auth/home");
      }
    } catch (error) {
      console.error("Error in NavHeader onPress:", error);
    }
  }, [userAuth, userFound, navigate, setOnLoginCallbackObj]);

  useEffect(() => {
    try {
      // Log device info only once on mount
      console.log("User in EventDetailView!! Device Info:", {
        userAgent: window.navigator.userAgent,
        platform: Platform.OS,
        language: window.navigator.language,
        cookiesEnabled: window.navigator.cookieEnabled,
        screenSize: {
          width: window.screen.width,
          height: window.screen.height,
        },
        viewport: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
        devicePixelRatio: window.devicePixelRatio,
        isMobile: isMobile,
      });

      // Check if incognito mode using type assertion
      const fs =
        (window as any).RequestFileSystem ||
        (window as any).webkitRequestFileSystem;
      if (fs) {
        fs(
          (window as any).TEMPORARY,
          100,
          () => console.log("Not in incognito mode"),
          () => console.log("Incognito mode detected")
        );
      }
    } catch (error) {
      console.error("Error in device info logging effect:", error);
    }
  }, []);

  useEffect(() => {
    try {
      if (
        userAuth &&
        event?.eventGoingUsers &&
        event.eventGoingUsers.includes(userAuth.user.uid)
      ) {
        setIsDownloadModalOpen(true);
      }
    } catch (error) {
      console.error("Error in download modal effect:", error);
    }
  }, [event?.id, userAuth?.user.uid]);

  useEffect(() => {
    try {
      if (event && event["eventGoingUsers"] && event["eventInvitedUsers"]) {
        setNumEventUsers(
          event["eventGoingUsers"].length + event["eventInvitedUsers"].length
        );
      }
    } catch (error) {
      console.error("Error in event users effect:", error);
    }
  }, [event?.eventGoingUsers?.length, event?.eventInvitedUsers?.length]);

  const goToCreateEvent = () => {
    try {
      if (userAuth && userAuth.user.uid && userFound) {
        navigate("/event/create");
      } else {
        setOnLoginCallbackObj({
          func: () => {
            return "/event/create";
          },
          name: "navToCreate",
        });
        navigate("/auth/home");
      }
    } catch (error) {
      console.error("Error in CreateManageRow onClick:", error);
    }
  };

  const goToEditEvent = () => {
    try {
      if (userAuth && userAuth.user.uid && userFound) {
        navigate("/event/edit", {
          state: { editEvent: event },
        });
      } else {
        setOnLoginCallbackObj({
          func: () => {
            return {
              route: "/event/edit",
              params: {
                state: {
                  editEvent: event,
                },
              },
            };
          },
          name: "navToEdit",
        });
        navigate("/auth/home");
      }
    } catch (error) {
      console.error("Error in edit event onClick:", error);
    }
  };

  return (
    <LinearGradient
      colors={[black, charcoal, black]}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 1 }}
      style={tw`flex-1 items-center w-full min-h-screen`}
    >
      <NavHeader onPress={handleNavHeaderPress} />
      {event && eventHostsUsers && eventGuestsUsers ? (
        <View style={tw`w-full max-w-7xl px-4 py-6`}>
          <View style={tw`flex ${isMobile ? "flex-col" : "flex-row"} gap-6`}>
            <View style={tw`flex-1 flex flex-col gap-4`}>
              <HeaderImage
                image={event["eventMedia"][0]}
                gradientIndex={event.eventGradientIndex}
              />
              <TitleRow
                title={event.eventTitle}
                start={event.eventStart}
                end={event.eventEnd}
                coordinates={event.eventLocation.locationCoordinates}
              />
              {!event.eventHideGuests && (
                <GuestListRow
                  eventGuestsUsers={eventGuestsUsers}
                  eventContacts={eventContacts}
                />
              )}
            </View>
            <View style={tw`flex-1 flex flex-col gap-4`}>
              <LocationRow
                address={event.eventLocation.locationAddress}
                coordinates={
                  event.eventLocation.locationCoordinates ?? {
                    latitude: 37.4275,
                    longitude: -122.1697,
                  }
                }
              />
              <TaggedPhotoPreview eventId={event.id} />
              <DescriptionRow description={event.eventDescription} />
              {eventArtistsUsers && eventArtistsUsers.length > 0 ? (
                <ArtistsRow eventArtistsUsers={eventArtistsUsers} />
              ) : null}
              {!event.eventHideHosts ||
              event.eventHosts.includes(userAuth ? userAuth.user.uid : null) ? (
                eventHostsUsers ? (
                  <HostsRow eventHostsUsers={eventHostsUsers} />
                ) : null
              ) : null}
              {event.eventOtherLinks && event.eventOtherLinks.length > 0 ? (
                <LinksRow links={event.eventOtherLinks} />
              ) : null}
              {event.eventExternalLink && event.eventExternalLink !== "" ? (
                <ExternalLinkRow
                  event={event}
                  userId={userAuth ? userAuth.user.uid : null}
                  onPress={() => {
                    try {
                      if (
                        userAuth &&
                        userAuth.user.uid &&
                        !event["eventGoingUsers"].includes(userAuth.user.uid)
                      ) {
                        updateGoingUsers(userAuth.user.uid, event);
                        addEventCodeToUser(userAuth.user.uid, event);
                      }
                      Linking.openURL(event.eventExternalLink);
                    } catch (error) {
                      console.error("Error in ExternalLinkRow onPress:", error);
                    }
                  }}
                  eventExternalLink={event.eventExternalLink}
                />
              ) : event.eventTicketTypes.length === 0 ? (
                <>
                  <NoTicketRow
                    userId={userAuth ? userAuth.user.uid : null}
                    event={event}
                    onPress={() => {
                      try {
                        if (
                          userAuth &&
                          userAuth.user.uid &&
                          event["eventGoingUsers"].includes(userAuth.user.uid)
                        ) {
                          window.location.replace(
                            "https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983"
                          );
                        } else if (userAuth && userAuth.user.uid && userFound) {
                          updateGoingUsers(userAuth.user.uid, event);
                          addEventCodeToUser(userAuth.user.uid, event);
                        } else {
                          setUrl(
                            window.location.pathname + window.location.search
                          );
                          setOnLoginCallbackObj({
                            updateGoingUsers: updateGoingUsers,
                            addEventCodeToUser: addEventCodeToUser,
                          });
                          navigate("/auth/home");
                        }
                      } catch (error) {
                        console.error("Error in NoTicketRow onPress:", error);
                      }
                    }}
                  />
                  <CustomModal
                    isOpen={isDownloadModalOpen}
                    onClose={() => setIsDownloadModalOpen(false)}
                    title="Avenu App"
                    content="Open the event page in the Avenu app to view guest list, Meet new people, receive event notifications, add tickets to Apple Wallet, post content, and more!"
                    showAppStoreButton={true}
                  />
                </>
              ) : (
                <>
                  <PurchaseRow
                    allTicketLimitReached={checkIfAllTicketLimitReached(event)}
                    userId={userAuth ? userAuth.user.uid : null}
                    event={event}
                    limitReached={numEventUsers >= 9999}
                    eventOnClick={handlePurchaseRowClick}
                    ticketOnClick={() => {
                      try {
                        if (userAuth && userFound) {
                          navigate("/tickets");
                        } else {
                          setOnLoginCallbackObj({
                            func: () => {
                              return "/tickets";
                            },
                            name: "navToTickets",
                          });
                          navigate("/auth/home");
                        }
                      } catch (error) {
                        console.error(
                          "Error in PurchaseRow ticketOnClick:",
                          error
                        );
                      }
                    }}
                  />
                  <CustomModal
                    isOpen={isPrivateModalOpen}
                    onClose={() => setIsPrivateModalOpen(false)}
                    title="Private Event"
                    content="This is a private event. Only guests invited directly by co-hosts can join. Contact a host to be invited."
                  />
                  <CustomModal
                    isOpen={isLimitModalOpen}
                    onClose={() => setIsLimitModalOpen(false)}
                    title="Event at Capacity"
                    content={
                      userAuth && event.eventHosts.includes(userAuth.user.uid)
                        ? "This event has 9,999 guests and is therefore at its limit. Create a second, concurrent event to handle larger event capacities."
                        : "This event has 9,999 guests and is therefore at its limit. Contact the event host to create a second, concurrent event to handle larger event capacities."
                    }
                  />
                  <CustomModal
                    isOpen={isDownloadModalOpen}
                    onClose={() => setIsDownloadModalOpen(false)}
                    title="Avenu App"
                    content="Open the event page in the Avenu app to view guest list, Meet new people, receive event notifications, add tickets to Apple Wallet, post content, and more!"
                    showAppStoreButton={true}
                  />
                </>
              )}

              <EventRefRow refCode={event.eventRefCode} />
            </View>
          </View>
          <View style={tw`flex flex-row gap-4 mt-8`}>
            <TouchableOpacity
              onPress={goToCreateEvent}
              style={tw`flex-1 w-3/5 self-center flex-row justify-center items-center bg-black rounded-3xl border-2 border-white shadow-lg z-0`}
            >
              <Text style={tw`text-lg text-white my-4 self-center`}>
                create your event
              </Text>
            </TouchableOpacity>
            {userAuth &&
              userAuth.user.uid &&
              userFound &&
              event.eventHosts.includes(userAuth.user.uid) && (
                <TouchableOpacity
                  onPress={goToEditEvent}
                  style={tw`flex-1 w-3/5 self-center flex-row justify-center items-center bg-[${color}] rounded-3xl border-2 border-white shadow-lg z-0`}
                >
                  <Text style={tw`text-lg text-black my-4 self-center`}>
                    edit event
                  </Text>
                </TouchableOpacity>
              )}
          </View>
          {isMobile && (
            <View
              style={{
                marginTop: "40px",
                marginBottom: "60px",
              }}
            />
          )}
        </View>
      ) : null}
    </LinearGradient>
  );
};

export default EventDetailView;
