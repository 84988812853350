import React, { useContext, useEffect, useState } from "react";
import { View, SafeAreaView } from "react-native";
import { useNavigate } from "react-router-dom";
import tw from "twrnc";

import { EventContext } from "../EventContext";
import { AuthContext } from "../../auth/AuthContext";
import { darkThemeBackground } from "../../../utils/color";

import { Plus } from "../../../utils/images";
import CircleButton from "../../../components/CircleButton";
import TextButtonSection from "../../../components/TextButtonSection";
import EventsListView from "../views/EventsListView/EventsListView";

const EventHostingScreen: React.FC = () => {
  const { fetchHosted, hostedEvents, readHostingEvents, setCurrEventId } =
    useContext(EventContext);
  const { userAuth } = useContext(AuthContext);

  const [section, setSection] = useState("UPCOMING");
  const navigate = useNavigate();

  // Fetch events when authenticated
  useEffect(() => {
    if (fetchHosted && userAuth && userAuth.user) {
      console.log("DEBUG read hosted events FIRED");
      readHostingEvents(userAuth.user.uid);
    }
  }, [userAuth, fetchHosted, readHostingEvents]);

  // Handle unauthenticated state
  if (!userAuth) {
    return null;
  }

  return (
    <SafeAreaView
        style={[
            tw`flex-1 justify-start items-center h-screen bg-[${darkThemeBackground}]`,
            { backgroundColor: darkThemeBackground, minHeight: "100vh" },
        ]}
    >
      {/* Section Header */}
      <TextButtonSection
        sections={[
          {
            title: "Upcoming",
            key: "UPCOMING",
            onPress: () => setSection("UPCOMING"),
          },
          {
            title: "Recurring",
            key: "RECURRING",
            onPress: () => setSection("RECURRING"),
          },
          {
            title: "Past",
            key: "PAST",
            onPress: () => setSection("PAST"),
          },
        ]}
        selected={section}
        theme="dark"
      />

      {/* Event List Section */}
      <View style={tw`flex-1 px-4`}>
        {section === "UPCOMING" && (
          <EventsListView
            data={hostedEvents.Upcoming}
            navigate={navigate}
            setCurrEventId={setCurrEventId}
            users={userAuth.user}
            userId={userAuth.user.uid}
            route="EventsHosting"
            upcoming
          />
        )}
        {section === "RECURRING" && (
          <EventsListView
            data={hostedEvents.Recurring}
            navigate={navigate}
            setCurrEventId={setCurrEventId}
            users={userAuth.user}
            userId={userAuth.user.uid}
            route="EventsHosting"
            recurring
          />
        )}
        {section === "PAST" && (
          <EventsListView
            data={hostedEvents.Past}
            navigate={navigate}
            setCurrEventId={setCurrEventId}
            users={userAuth.user}
            userId={userAuth.user.uid}
            route="EventsHosting"
          />
        )}
      </View>

      {/* Floating Add Button */}
      <View style={tw`absolute bottom-5 right-5`}>
        <CircleButton icon={Plus} onPress={() => navigate("/event/create")} />
      </View>
    </SafeAreaView>
  );
};

export default EventHostingScreen;
