import React from "react";
import { TouchableOpacity } from "react-native";
import IconImage from "../IconImage";

import style from "./style";

const IconButton = (props) => (
    <TouchableOpacity
        style={props.style ?? style.button}
        onPress={props.onPress}
        disabled={props.disabled}
    >
        <IconImage
            theme={props.theme}
            icon={props.icon}
            size={props.size ?? 20}
            onPress={props.onPress}
            tintColor={props.tintColor}
            disabled={props.disabled}
        />
    </TouchableOpacity>
);

export default React.memo(IconButton);
