import React from "react";
import { Platform, TextInput, View } from "react-native";
import PropTypes from "prop-types";
import { TextareaAutosize } from "@mui/material";

import { ash, charcoal, green, snow, steel } from "../../utils/color";

import style from "./style";

const getLineStyle = (value, focused, error) =>
  error
    ? [style.line, style.lineError]
    : value !== "" || focused
      ? style.line
      : [style.line, style.lineDisabled];

const MultiTextField = (props) => (
  <View style={style.field}>
    <View style={style.container}>
      <TextareaAutosize
        theme={"dark"}
        style={style.text}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        minRows={props.minRows}
        maxRows={props.maxRows}
      />
    </View>
    <View style={getLineStyle(props.value, props.focused, props.error)} />
  </View>
);

MultiTextField.defaultProps = {
  placeholder: "",
  returnKey: Platform.OS === "ios" ? "default" : "return",
  keyboardType: "default",
  onChangeText: () => { },
  onChange: () => { },
  onKeyPress: () => { },
  onSubmitEditing: () => { },
  onBlur: () => { },
  onFocus: () => { },
  autoCapitalize: "sentences",
  editable: true,
  focused: false,
  textAlign: "left",
  setRef: null,
  error: false,
};

MultiTextField.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  keyboardType: PropTypes.string,
  returnKey: PropTypes.string,
  onChangeText: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onSubmitEditing: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  autoCapitalize: PropTypes.string,
  editable: PropTypes.bool,
  focused: PropTypes.bool,
  textAlign: PropTypes.string,
  setRef: PropTypes.object,
  error: PropTypes.bool,
};

export default MultiTextField;