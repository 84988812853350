import React, { useState } from "react";
import { View, TouchableOpacity, Image } from "react-native";
import NavHeader from "../../../../../components/NavHeader/NavHeader";
import MediaField from "../../../../../components/MediaField";
import ActionRow from "../../../../../components/ActionRow";
import IconImage from "../../../../../components/IconImage";
import SwitchRow from "../../../../../components/SwitchRow";
import BubbleButton from "../../../../../components/BubbleButton";
import { Modal, Box } from "@mui/material";
import Webcam from "react-webcam";
import PopupModal from "../../../../../components/PopupModal";

import {
  ash,
  charcoal,
  white,
  steel,
  snow,
  sky,
} from "../../../../../utils/color";
import {
  CalendarIcon,
  CameraIcon,
  CheckIcon,
  ImageIcon,
  GroupIcon,
  LocationIcon,
  PlusIcon,
  TicketIcon,
  UsersIcon,
  KeyIcon,
  TooltipIcon,
  UploadIcon,
  ShareIcon,
  Cancel,
  Check,
  LinkIcon,
} from "../../../../../utils/icons";

import Tooltip from "../../../../../components/Tooltip";

import {
  Gradient1,
  Gradient2,
  Gradient3,
  Gradient4,
  Gradient5,
} from "../../../utils/images";

import style from "./style";
import IconButton from "../../../../../components/IconButton";
import { Timestamp } from "firebase/firestore";

const handleChooseGradient = async (setMedia, setGradientIndex, index) => {
  setMedia([]);
  setGradientIndex(index);
};

const isDetailsEdited = (event, original) => {
  const eventStart = new Timestamp(
    original.eventStart.seconds,
    original.eventStart.nanoseconds
  ).toDate();
  const eventEnd = new Timestamp(
    original.eventEnd.seconds,
    original.eventEnd.nanoseconds
  ).toDate();
  return (
    event.eventTitle !== original.eventTitle ||
    event.eventDescription !== original.eventDescription ||
    event.eventStart.getTime() !== eventStart.getTime() ||
    event.eventEnd.getTime() !== eventEnd.getTime() ||
    event.eventRecurring !== original.eventRecurring
  );
};

const isLocationEdited = (event, original) =>
  event.eventLocation.locationAddress !==
  original.eventLocation.locationAddress;

const isPersonnelEdited = (event, original) => {
  // Create sets for original and new hosts and scanners
  const originalHostSet = new Set(original.eventHosts);
  const originalArtistSet = new Set(original.eventArtists);
  const originalScannerSet = new Set(original.eventTicketScanners);
  const newHostSet = new Set(event.eventHosts);
  const newArtistSet = new Set(event.eventArtists);
  const newScannerSet = new Set(event.eventTicketScanners);

  // Get lists of added and removed hosts and scanners
  const addedHosts = [...newHostSet].filter(
    (host) => !originalHostSet.has(host)
  );
  const removedHosts = [...originalHostSet].filter(
    (host) => !newHostSet.has(host)
  );
  const addedArtists = [...newArtistSet].filter(
    (artist) => !originalArtistSet.has(artist)
  );
  const removedArtists = [...originalArtistSet].filter(
    (artist) => !newScannerSet.has(artist)
  );
  const addedScanners = [...newScannerSet].filter(
    (scanner) => !originalScannerSet.has(scanner)
  );
  const removedScanners = [...originalScannerSet].filter(
    (scanner) => !newScannerSet.has(scanner)
  );

  // If necessary, handle removed hosts and scanners...

  // Return whether there was a personnel change
  return (
    addedHosts.length > 0 ||
    removedHosts.length > 0 ||
    addedScanners.length > 0 ||
    removedScanners.length > 0 ||
    addedArtists.length > 0 ||
    removedArtists.length > 0
  );
};

// implement when ticketing feature is added
const isTicketsEdited = (event, original) => {
  if (event.eventTicketTypes.length !== original.eventTicketTypes.length)
    return true;

  for (let i = 0; i < event.eventTicketTypes.length; i++) {
    const eventTicket = event.eventTicketTypes[i];
    const originalTicket = original.eventTicketTypes[i];

    // Convert both releaseTimes to JavaScript Date objects
    let eventReleaseTime, originalReleaseTime;

    if (eventTicket.releaseTime) {
      if (
        typeof eventTicket.releaseTime === "object" &&
        "seconds" in eventTicket.releaseTime
      ) {
        eventReleaseTime = new Date(eventTicket.releaseTime.seconds * 1000); // convert seconds to milliseconds
      } else {
        // Check if ISO format
        eventReleaseTime = new Date(eventTicket.releaseTime);
      }
    }

    if (originalTicket.releaseTime) {
      if (
        typeof originalTicket.releaseTime === "object" &&
        "seconds" in originalTicket.releaseTime
      ) {
        originalReleaseTime = new Date(
          originalTicket.releaseTime.seconds * 1000
        ); // convert seconds to milliseconds
      } else {
        // Check if ISO format
        originalReleaseTime = new Date(originalTicket.releaseTime);
      }
    }

    let ticketReleaseTimeEdited = false;
    let scheduledEdited = false;

    if (originalTicket.releaseTime && eventTicket.releaseTime) {
      ticketReleaseTimeEdited =
        eventReleaseTime.getTime() !== originalReleaseTime.getTime();
    }
    if (eventTicket.hasOwnProperty("isScheduledTicket")) {
      scheduledEdited =
        eventTicket.isScheduledTicket !== originalTicket.isScheduledTicket;
    }
    if (
      eventTicket.ticketName !== originalTicket.ticketName ||
      eventTicket.ticketPrice !== originalTicket.ticketPrice ||
      eventTicket.ticketLimit !== originalTicket.ticketLimit ||
      eventTicket.ticketPerGuestLimit !== originalTicket.ticketPerGuestLimit ||
      eventTicket.ticketCode !== originalTicket.ticketCode ||
      ticketReleaseTimeEdited ||
      scheduledEdited
    ) {
      return true;
    }
  }

  return false;
};

const isLinkEdited = (event, original) => {
  return event.eventExternalLink !== original.eventExternalLink;
};

const isInvitedGuestsEdited = (event, original) => {
  const originalSet = new Set(original.eventInvitedUsers);

  // return true if guest lists are not the same size
  if (event.eventInvitedUsers.length !== original.eventInvitedUsers.length)
    return true;

  // if guest lists are the same size, verify that guests are the same in both list
  return !event.eventInvitedUsers.every((guest) => originalSet.has(guest));
};

const areLinksEdited = (event, original) => {
  if (original.eventOtherLinks) {
    if (event.eventOtherLinks.length !== original.eventOtherLinks.length) {
      return true;
    }
    for (let i = 0; i < event.eventOtherLinks.length; i++) {
      const eventLink = event.eventOtherLinks[i];
      const originalLink = original.eventOtherLinks[i];
      if (
        eventLink.linkName !== originalLink.linkName ||
        eventLink.link !== originalLink.link ||
        eventLink.linkType !== originalLink.linkType
      ) {
        return true;
      }
    }
  } else {
    // in the case where there were no links, if there are now links, return true
    if (event.eventOtherLinks) {
      return event.eventOtherLinks.length > 0;
    } else {
      return false;
    }
  }
};

// const isContactsEdited = (event, original) => {
//   if (original.eventContacts && event.contacts.added.length !== original.eventContacts.length)
//     return true;

//   const originalSet = new Set(original.eventContacts.map((contact) => contact.phoneNumber) ?? []);
//   return !event.contacts.added.every((contact) => originalSet.has(contact.phoneNumber));
// };

const isMediaEdited = (event, original) =>
  event.eventMedia[0] !== original.eventMedia[0];

const isPrivacyEdited = (event, original) =>
  event.eventPrivacy !== original.eventPrivacy;

const isHideGuestsEdited = (event, original) =>
  event.eventHideGuests !== original.eventHideGuests;

const isHideHostsEdited = (event, original) => {
  return event.eventHideHosts !== original.eventHideHosts;
};

const isGuestsCanInviteEdited = (event, original) =>
  event.eventGuestsCanInvite !== original.eventGuestsCanInvite;

const isComplete = (event, original, isEditEvent) => {
  if (isEditEvent) {
    return (
      isDetailsEdited(event, original) ||
      isLocationEdited(event, original) ||
      isPersonnelEdited(event, original) ||
      isTicketsEdited(event, original) ||
      isLinkEdited(event, original) ||
      isInvitedGuestsEdited(event, original) ||
      isMediaEdited(event, original) ||
      isPrivacyEdited(event, original) ||
      isHideGuestsEdited(event, original) ||
      isHideHostsEdited(event, original) ||
      isGuestsCanInviteEdited(event, original) ||
      areLinksEdited(event, original)
    );
  } else {
    return isDetailsComplete(event) && isLocationComplete(event);
  }
};

const getUpdatedFields = (event, fields) => {
  const updated = {};

  Object.keys(fields).forEach((key) => {
    if (key === "eventLocation") {
      if (
        event[key].locationName !== fields[key].locationName ||
        event[key].locationAddress !== fields[key].locationAddress
      ) {
        updated[key] = fields[key];
      }
    } else if (key === "eventStart") {
      const eventStart = new Timestamp(
        event.eventStart.seconds,
        event.eventStart.nanoseconds
      ).toDate();
      if (eventStart.getTime() !== fields[key].getTime())
        updated[key] = fields[key];
    } else if (key === "eventEnd") {
      const eventEnd = new Timestamp(
        event.eventEnd.seconds,
        event.eventEnd.nanoseconds
      ).toDate();
      if (eventEnd.getTime() !== fields[key].getTime())
        updated[key] = fields[key];
    } else if (key === "eventMedia") {
      if (event[key][0] !== fields[key][0]) updated[key] = fields[key];
    } else {
      if (event[key] !== fields[key]) updated[key] = fields[key];
    }
  });

  const originalSet = new Set([
    ...event.eventInvitedUsers,
    ...event.eventGoingUsers,
  ]);
  const updatedSet = [...fields.eventInvitedUsers, ...fields.eventGoingUsers];
  if (originalSet.length !== updatedSet.length) {
    updated.eventInvites = fields.eventInvites;
  } else {
    if (!updatedSet.every((guest) => originalSet.has(guest.id))) {
      updated.eventInvites = fields.eventInvites;
    }
  }

  return updated;
};

const isDetailsComplete = (event) =>
  event.eventTitle !== "" && event.eventDescription !== "" && event.eventStart;

const getDetailsIcon = (event) =>
  isDetailsComplete(event) ? CheckIcon : CalendarIcon;

const getDetailsTint = (event, theme) =>
  isDetailsComplete(event)
    ? theme === "dark"
      ? white
      : charcoal
    : theme === "dark"
    ? steel
    : ash;

const getDetailsRoute = (event) =>
  isDetailsComplete(event) ? "ManageEventDetails" : "CreateEventName";

const isLocationComplete = (event) => event.eventLocation.locationAddress;
const getLocationIcon = (event) =>
  isLocationComplete(event) ? CheckIcon : LocationIcon;
const getLocationTint = (event, theme) =>
  isLocationComplete(event)
    ? theme === "dark"
      ? white
      : charcoal
    : theme === "dark"
    ? steel
    : ash;

const getLocationRoute = (event) =>
  isLocationComplete(event) ? "ManageEventLocation" : "CreateEventAddress";

const isPersonnelComplete = (event) => event.personnel;
const getPersonnelIcon = (event) =>
  isPersonnelComplete(event) ? CheckIcon : UsersIcon;
const getPersonnelTint = (event, theme) =>
  isPersonnelComplete(event)
    ? theme === "dark"
      ? white
      : charcoal
    : theme === "dark"
    ? steel
    : ash;

const isTicketsComplete = (event) => event.tickets;
const getTicketsIcon = (event) =>
  isTicketsComplete(event) ? CheckIcon : TicketIcon;
const getTicketsTint = (event, theme) =>
  isTicketsComplete(event)
    ? theme === "dark"
      ? white
      : charcoal
    : theme === "dark"
    ? steel
    : ash;

const isGuestsComplete = (event) => event.guests;
const getGuestsIcon = (event) =>
  isGuestsComplete(event) ? CheckIcon : GroupIcon;
const getLinkIcon = (event) => (isGuestsComplete(event) ? CheckIcon : LinkIcon);
const getGuestsTint = (event, theme) =>
  isGuestsComplete(event)
    ? theme === "dark"
      ? white
      : charcoal
    : theme === "dark"
    ? steel
    : ash;

const getTooltipIconColor = (theme) => (theme === "dark" ? snow : charcoal);

const onCreateEvent = async (
  event,
  host,
  personnel,
  createEvent,
  navigate,
  eventRef,
  eventId,
  recurringEventId,
  setCurrEventId,
  readEvent
) => {
  const modifiedEvent = { ...event }; // Make a copy of the 'event' object

  if (modifiedEvent.eventGradientIndex == null) {
    modifiedEvent.eventGradientIndex = Math.floor(Math.random() * 5);
  }
  const fcmData = {
    userIds: event.eventInvitedUsers,
    hostIds: event.eventHosts,
    userNotification: {
      body: `${host.userFirst} has invited you to an event`,
    },
    hostNotification: {
      body: `${host.userFirst} has added you as a co-host for an event`,
    },
  };
  if (!event.eventPrivacy && event.eventAnnounce) {
    let friends = [];
    personnel.forEach((host) => {
      if (friends && host.userFriends) {
        friends = [...new Set([...friends, ...host.userFriends])];
      } else if (!friends && host.userFriends) {
        friends = [...host.userFriends];
      }
    });
    fcmData.friendIds = friends;
    fcmData.friendNotification = {
      body: `${host.userFirst} created an open event, ${event.eventTitle}! 🔥`,
    };
  }

  const eventData = {
    ...modifiedEvent,
    eventRefCode: eventRef,
  };

  createEvent(
    eventId,
    recurringEventId,
    eventData,
    fcmData,
    setCurrEventId,
    readEvent,
    navigate
  );

  inviteContacts(event, host, eventId, eventRef);
};

const onUpdateEvent = async (
  event,
  contacts,
  updatedEvent,
  host,
  hostID,
  updateEvent,
  navigate,
  addEventCodeToUser,
  readEvent,
  changeTicketReleaseNotificationStatus,
  updateUnreleasedTickets
) => {
  // inviteContacts(contacts, event, host);

  // Find newly added users and users who got removed
  const newlyAddedUsers = updatedEvent.eventInvitedUsers.filter(
    (id) => !event.eventInvitedUsers.includes(id)
  );
  const removedUsers = event.eventInvitedUsers.filter(
    (id) => !updatedEvent.eventInvitedUsers.includes(id)
  );

  const newlyAddedHosts = updatedEvent.eventHosts.filter(
    (id) => !event.eventHosts.includes(id)
  );
  const newlyAddedScanners = updatedEvent.eventTicketScanners.filter(
    (id) => !event.eventTicketScanners.includes(id)
  );
  const removedHosts = event.eventHosts.filter(
    (id) => !updatedEvent.eventHosts.includes(id)
  );

  // Add eventId to new users and hosts
  newlyAddedUsers
    .concat(newlyAddedHosts.concat(newlyAddedScanners))
    .forEach((user) => addEventCodeToUser(user, event.id));

  // Call changeTicketReleaseNotificationStatus for newly added and removed users/hosts
  // newlyAddedUsers
  //   .concat(newlyAddedHosts)
  //   .forEach((user) => changeTicketReleaseNotificationStatus(event.id, user, true));
  // removedUsers
  //   .concat(removedHosts)
  //   .forEach((user) => changeTicketReleaseNotificationStatus(event.id, user, false));

  const fcmData = {
    userIds: newlyAddedUsers,
    hostIds: updatedEvent.eventHosts.filter(
      (id) => !event.eventHosts.includes(id)
    ),
    userNotification: {
      body: `${host.userFirst} has invited you to an event`,
    },
    hostNotification: {
      body: `${host.userFirst} has added you as a co-host for an event`,
    },
  };

  const detailsChanged =
    new Timestamp(event.eventStart.seconds, event.eventStart.nanoseconds)
      .toDate()
      .getTime() !== updatedEvent.eventStart.getTime() ||
    new Timestamp(event.eventEnd.seconds, event.eventEnd.nanoseconds)
      .toDate()
      .getTime() !== updatedEvent.eventEnd.getTime() ||
    isLocationEdited(event, updatedEvent);

  if (detailsChanged) {
    fcmData.userChangeIds = [
      ...updatedEvent.eventGoingUsers,
      ...updatedEvent.eventInvitedUsers,
    ];
    fcmData.eventNotification = {
      body: `${host.userFirst} has changed ${updatedEvent.eventTitle} details`,
    };
  }

  const updatedFields = getUpdatedFields(event, updatedEvent);

  // updatedFields.eventTicketTypes = await updateUnreleasedTickets(
  //   updatedFields.eventTicketTypes,
  //   event.id
  // );

  updateEvent(
    event.id,
    updatedFields,
    fcmData,
    event.eventRecurringId,
    readEvent,
    navigate
  );
};

const inviteContacts = (event, host, eventId, eventRef) => {
  const contactNumbers = event.eventContacts.map(
    (contact) => contact.phoneNumber
  );

  // Form message
  const message = `${host.userFirst} ${host.userLast} invited you to ${event.eventTitle}
  \n✅  RSVP details
  \nhttps://avenuapp.co/event?eventId=${eventId}
  \n🔑 Event Code ${eventRef}
  \n📱Or enter the Event Code in-app to view full guest list, post to event photo albums, meet new friends, and more...
  \nhttps://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983
  `;

  // Send texts
  contactNumbers.forEach((number) => sendNumber(number, message));
};

const sendNumber = async (phoneNumber, message) => {
  const result = await fetch(
    "https://avenu-text-processing-server.ue.r.appspot.com/api/messages",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ to: phoneNumber, body: message }),
    }
  );
  const body = await result.json();
};

const videoConstraints = {
  width: 1280,
  height: 720,
};

const EventCreateView = (props) => {
  const isEditEvent = props.location.pathname === "/event/edit";
  const [contact, onChangeContact] = useState("");

  const [mediaOpen, setMediaOpen] = useState(false);
  const [photoLibraryOpen, setPhotoLibraryOpen] = useState(false);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [displayPrivateEventTooltip, setDisplayPrivateEventTooltip] =
    useState(false);
  const [displayAnnounceEventTooltip, setDisplayAnnounceEventTooltip] =
    useState(false);
  const [displayHideGuestsTooltip, setDisplayHideGuestsTooltip] =
    useState(false);
  const [displayHideHostsTooltip, setDisplayHideHostsTooltip] = useState(false);
  const [displayGuestToGuestTooltip, setDisplayGuestToGuestTooltip] =
    useState(false);

  const gradients = [Gradient1, Gradient2, Gradient3, Gradient4, Gradient5];

  const hiddenFileInput = React.useRef(null);
  const webCamRef = React.useRef(null);
  const [recentPhoto, setRecentPhoto] = useState(null);

  const capture = React.useCallback(() => {
    setRecentPhoto(webCamRef.current.getScreenshot());
  }, [webCamRef]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setMediaOpen(false);
    props.setMedia([fileUploaded]);
  };
  const eventInvites = {};
  props.invitedGuests
    .filter((user) =>
      props.original
        ? !props.original.eventInvitedUsers.includes(user.id)
        : true
    )
    .forEach((invite) => {
      eventInvites[invite.id] = props.userId;
    });

  const hostFriends = [];
  props.personnel.forEach((personnel) =>
    hostFriends.push(...personnel.userFriends)
  );
  hostFriends.push(...props.user.userFriends);

  // convert graphQL format to firebase format
  const event = {
    eventTitle: props.name ?? "",
    eventSearch: props.name ? props.name.toLowerCase() : "",
    eventDescription: props.description ?? "",
    eventPromotion: "",
    eventStart: props.start,
    eventEnd: props.end,
    eventRecurring: props.recurringInterval,
    eventLocation: {
      locationAddress: props.address,
      locationCoordinates: props.coordinates,
      locationHash: "",
      locationName: "",
    },
    eventMedia: props.media,
    eventHosts: Array.from(
      new Set(
        [props.userId].concat(props.personnel.map((personnel) => personnel.id))
      )
    ),
    eventArtists: props.artists.map((artists) => artists.id),
    eventContact: contact,
    eventTicketTypes: props.tickets,
    eventTickets: [],
    eventExternalLink: props.link,
    eventOtherLinks: props.eventOtherLinks,
    eventInvitedUsers: props.invitedGuests.map((guest) => guest.id),
    eventTicketScanners: props.ticketScanners.map((scanners) => scanners.id),
    eventGoingUsers: Array.from(
      new Set(
        [props.userId].concat(props.personnel.map((personnel) => personnel.id))
      )
    ),
    eventContacts: props.original ? props.original.eventContacts : [],
    eventComments: [],
    eventPrivacy: props.privacy,
    eventPublic: props.user.userVerified ? !props.privacy : false,
    eventHideGuests: props.hideGuests,
    eventHideHosts: props.hideHosts,
    eventGuestsCanInvite: props.canInvite,
    eventAnnounce: props.announce,
    eventInvites: props.original
      ? { ...props.original.eventInvites, ...eventInvites }
      : eventInvites,
    eventRecommendedIds: props.privacy
      ? Array.from(
          new Set(
            [props.userId]
              .concat(props.personnel.map((personnel) => personnel.id))
              .concat(props.invitedGuests.map((guest) => guest.id))
              .concat(props.original ? props.original.eventGoingUsers : [])
          )
        )
      : Array.from(
          new Set(
            [props.userId]
              .concat(props.personnel.map((personnel) => personnel.id))
              .concat(
                hostFriends.concat(props.invitedGuests.map((guest) => guest.id))
              )
              .concat(props.original ? props.original.eventGoingUsers : [])
          )
        ),
    createdBy: props.userId,
    eventGradientIndex: props.gradientIndex,
  };

  return (
    <View style={style.container}>
      <NavHeader
        title={`${!isEditEvent ? "New Event" : props.name}`}
        onPress={() => {
          isEditEvent
            ? props.navigate(`/event?eventId=${props.currEventId}`)
            : props.navigate("/event/host");
          props.clear();
        }}
      />
      <View style={style.line} />
      <View style={style.body}>
        <View style={style.card}>
          <MediaField
            image={props.media[0]}
            gradient={
              props.gradientIndex !== null
                ? gradients[props.gradientIndex]
                : null
            }
            onPress={() => setMediaOpen(true)}
          />
          <View style={{ marginTop: -20 }}>
            <ActionRow
              theme={props.theme}
              icon={KeyIcon}
              title={`Event Code: ${props.eventRef}`}
              onPress={() => {}}
              tooltip={
                <TouchableOpacity style={style.shareButton} onPress={() => {}}>
                  <IconImage
                    theme={props.theme}
                    icon={ShareIcon}
                    size={25}
                    tintColor={"white"}
                    onPress={() => {}}
                  />
                </TouchableOpacity>
              }
            />
          </View>
        </View>
        <View style={{ marginLeft: 10, alignItems: "flex-start" }}>
          <View style={{ ...style.line, width: "calc(5rem + 60vmin)" }} />
          <ActionRow
            theme={props.theme}
            icon={isEditEvent ? CalendarIcon : getDetailsIcon(event)}
            title="Details"
            onPress={() => {
              isEditEvent ? props.navigate("details") : props.navigate("name");
            }}
            tintColor={"white"}
          />
          <View style={{ ...style.line, width: "calc(5rem + 60vmin)" }} />
          <ActionRow
            theme={props.theme}
            icon={isEditEvent ? LocationIcon : getLocationIcon(event)}
            title="Location"
            onPress={() => props.navigate("location")}
            tintColor={"white"}
          />
          <View style={{ ...style.line, width: "calc(5rem + 60vmin)" }} />
          <ActionRow
            theme={props.theme}
            icon={isEditEvent ? UsersIcon : getPersonnelIcon(props.uiCompleted)}
            title="Personnel"
            onPress={() => props.navigate("personnel")}
            tintColor={"white"}
          />
          <View style={{ ...style.line, width: "calc(5rem + 60vmin)" }} />
          <ActionRow
            theme={props.theme}
            icon={isEditEvent ? TicketIcon : getTicketsIcon(props.uiCompleted)}
            title="Tickets"
            onPress={() => props.navigate("tickets")}
            tintColor={"white"}
          />
          <View style={{ ...style.line, width: "calc(5rem + 60vmin)" }} />
          <ActionRow
            theme={props.theme}
            icon={isEditEvent ? GroupIcon : getGuestsIcon(props.uiCompleted)}
            title="Guest List"
            onPress={() => props.navigate("guests")}
          />
          <View style={{ ...style.line, width: "calc(5rem + 60vmin)" }} />
          <ActionRow
            theme={props.theme}
            icon={isEditEvent ? LinkIcon : getLinkIcon(props.uiCompleted)}
            title="Links"
            onPress={() => props.navigate("links")}
          />
          <View style={{ ...style.line, width: "calc(5rem + 60vmin)" }} />
          <SwitchRow
            title="Private Event"
            value={props.privacy}
            onChangeValue={props.onChangePrivacy}
            tooltip={
              <TouchableOpacity
                style={style.tooltipButton}
                onPress={() => setDisplayPrivateEventTooltip(true)}
              >
                <IconImage
                  theme={"dark"}
                  icon={TooltipIcon}
                  size={16}
                  tintColor={getTooltipIconColor("dark")}
                  onPress={() => {
                    setDisplayPrivateEventTooltip(true);
                  }}
                />
              </TouchableOpacity>
            }
          >
            <PopupModal
              displayState={displayPrivateEventTooltip}
              setDisplayState={setDisplayPrivateEventTooltip}
              theme={"dark"}
            >
              <Tooltip
                theme="dark"
                header="Private Event"
                text="Private events are only visible to guests & contacts invited in-app, with event links, or with the event code. Non-private events are visible to all friends of the cohosts.\n\nFor verified users, non-private events are public to all nearby Avenu users."
              />
            </PopupModal>
          </SwitchRow>
          <View style={{ ...style.line, width: "calc(5rem + 60vmin)" }} />
          {props.privacy ? null : (
            <>
              <SwitchRow
                title="Announce Event"
                value={props.announce}
                onChangeValue={props.onChangeAnnounce}
                tooltip={
                  <TouchableOpacity
                    style={style.tooltipButton}
                    onPress={() => setDisplayAnnounceEventTooltip(true)}
                  >
                    <IconImage
                      theme={"dark"}
                      icon={TooltipIcon}
                      size={16}
                      tintColor={getTooltipIconColor("dark")}
                      onPress={() => {
                        setDisplayAnnounceEventTooltip(true);
                      }}
                    />
                  </TouchableOpacity>
                }
              >
                <PopupModal
                  displayState={displayAnnounceEventTooltip}
                  setDisplayState={setDisplayAnnounceEventTooltip}
                  theme={"dark"}
                >
                  <Tooltip
                    theme="dark"
                    header="Announce Event"
                    text="Non-private events are announced with a push notification to all friends of the event creator. Verified users can request their event be announced to all nearby Avenu users"
                  />
                </PopupModal>
              </SwitchRow>
              <View
                style={{
                  ...style.line,
                  width: "calc(5rem + 60vmin)",
                }}
              />
            </>
          )}
          <SwitchRow
            title="Hide Guests"
            value={props.hideGuests}
            onChangeValue={props.setHideGuests}
            tooltip={
              <TouchableOpacity
                style={style.tooltipButton}
                onPress={() => setDisplayHideGuestsTooltip(true)}
              >
                <IconImage
                  theme={"dark"}
                  icon={TooltipIcon}
                  size={16}
                  tintColor={getTooltipIconColor("dark")}
                  onPress={() => {
                    setDisplayHideGuestsTooltip(true);
                  }}
                />
              </TouchableOpacity>
            }
          >
            <PopupModal
              displayState={displayHideGuestsTooltip}
              setDisplayState={setDisplayHideGuestsTooltip}
              theme={"dark"}
            >
              <Tooltip
                theme="dark"
                header="Hide Guests"
                text="Hidden guest lists will only be visible to event co-hosts."
              />
            </PopupModal>
          </SwitchRow>
          <View style={{ ...style.line, width: "calc(5rem + 60vmin)" }} />
          <SwitchRow
            title="Hide Hosts"
            value={props.hideHosts}
            onChangeValue={props.setHideHosts}
            tooltip={
              <TouchableOpacity
                style={style.tooltipButton}
                onPress={() => setDisplayHideHostsTooltip(true)}
              >
                <IconImage
                  theme={"dark"}
                  icon={TooltipIcon}
                  size={16}
                  tintColor={getTooltipIconColor("dark")}
                  onPress={() => {
                    setDisplayHideHostsTooltip(true);
                  }}
                />
              </TouchableOpacity>
            }
          >
            <PopupModal
              displayState={displayHideHostsTooltip}
              setDisplayState={setDisplayHideHostsTooltip}
              theme={"dark"}
            >
              <Tooltip
                theme="dark"
                header="Hide Hosts"
                text="Hosts will only be visible to event co-hosts."
              />
            </PopupModal>
          </SwitchRow>
          <View style={{ ...style.line, width: "calc(5rem + 60vmin)" }} />
          <SwitchRow
            title="Guest-to-Guest Invitations"
            value={props.canInvite}
            onChangeValue={props.setCanInvite}
            tooltip={
              <TouchableOpacity
                style={style.tooltipButton}
                onPress={() => setDisplayGuestToGuestTooltip(true)}
              >
                <IconImage
                  theme={"dark"}
                  icon={TooltipIcon}
                  size={16}
                  tintColor={getTooltipIconColor("dark")}
                  onPress={() => {
                    setDisplayGuestToGuestTooltip(true);
                  }}
                />
              </TouchableOpacity>
            }
          >
            <PopupModal
              displayState={displayGuestToGuestTooltip}
              setDisplayState={setDisplayGuestToGuestTooltip}
              theme={"dark"}
            >
              <Tooltip
                theme="dark"
                header="Guest-to-Guest Invitations"
                text="Allows guests to invite their friends in-app, with event link, or with event code"
              />
            </PopupModal>
          </SwitchRow>
          <View
            style={{
              ...style.line,
              width: "calc(5rem + 60vmin)",
              marginBottom: 20,
            }}
          />
          <View style={{ height: "15vh" }} />
        </View>
      </View>
      {isComplete(event, props.original, isEditEvent) ? (
        <BubbleButton
          title={`${isEditEvent ? "Confirm" : "Create"}`}
          onPress={() => {
            if (isEditEvent) {
              onUpdateEvent(
                props.original,
                props.contacts,
                event,
                props.user,
                props.userId,
                props.updateEvent,
                props.navigate,
                props.addEventCodeToUser,
                props.readEvent,
                props.changeTicketReleaseNotificationStatus,
                props.updateUnreleasedTickets
              );
            } else {
              onCreateEvent(
                event,
                props.user,
                props.personnel,
                props.createEvent,
                props.navigate,
                props.eventRef,
                props.eventId,
                props.recurringEventId,
                props.setCurrEventId,
                props.readEvent
              );

              // add event code to each invited user
              event.eventInvitedUsers.forEach((userId) => {
                props.addEventCodeToUser(userId, props.eventId);
              });

              // add event code to each event host
              event.eventHosts.forEach((userId) => {
                props.addEventCodeToUser(userId, props.eventId);
              });
            }
          }}
          bubbleStyle={style.buttonBubble}
          titleStyle={style.buttonTitle}
          buttonStyle={style.buttonStyle}
          color={sky}
          disabled={false}
        />
      ) : null}
      <Modal
        open={mediaOpen}
        onClose={() => setMediaOpen(false)}
        style={style.modal}
      >
        <View
          onPress={() => setMediaOpen(false)}
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <TouchableOpacity
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
            }}
            onPress={() => setMediaOpen(false)}
          />
          <Box style={style.box}>
            <ActionRow
              theme={props.theme}
              icon={UploadIcon}
              title="Upload from Photos"
              onPress={() => handleClick()}
            />
            <input
              ref={hiddenFileInput}
              style={{ display: "none" }}
              type="file"
              name="myimage"
              accept="image/*"
              onChange={handleChange}
            />
            <ActionRow
              theme={props.theme}
              icon={ImageIcon}
              title="Event Photo Library"
              onPress={() => setPhotoLibraryOpen(true)}
            />
            <ActionRow
              theme={props.theme}
              icon={CameraIcon}
              title="Camera"
              onPress={() => setCameraOpen(true)}
            />
          </Box>
        </View>
      </Modal>
      <Modal
        open={photoLibraryOpen}
        onClose={() => setPhotoLibraryOpen(false)}
        style={style.modal}
      >
        <Box style={style.gradientBox}>
          <View style={style.gradientContainer}>
            {gradients.map((gradient, index) => (
              <TouchableOpacity
                onPress={() => {
                  handleChooseGradient(
                    props.setMedia,
                    props.setGradientIndex,
                    index
                  );
                  setMediaOpen(false);
                  setPhotoLibraryOpen(false);
                }}
              >
                <Image source={gradient} style={style.gradientImage} />
              </TouchableOpacity>
            ))}
          </View>
        </Box>
      </Modal>
      <Modal
        open={cameraOpen}
        onClose={() => {
          setRecentPhoto(null);
          setCameraOpen(false);
        }}
      >
        <>
          <View style={style.camera}>
            {!recentPhoto ? (
              <Webcam
                videoConstraints={videoConstraints}
                width={"100%"}
                height={"80%"}
                ref={webCamRef}
                audio={false}
              />
            ) : (
              <img src={recentPhoto} style={{ height: "100", width: "100" }} />
            )}
          </View>
          {!recentPhoto ? (
            <TouchableOpacity style={style.capture} onPress={capture}>
              <IconButton icon={CameraIcon} size={50} onPress={capture} />
            </TouchableOpacity>
          ) : (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <TouchableOpacity
                style={style.select}
                onPress={() => setRecentPhoto(null)}
              >
                <IconButton
                  icon={Cancel}
                  tintColor={"red"}
                  size={50}
                  onPress={() => setRecentPhoto(null)}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={style.select}
                onPress={() => {
                  props.setMedia(recentPhoto);
                  setRecentPhoto(null);
                }}
              >
                <IconButton
                  icon={Check}
                  tintColor={sky}
                  size={50}
                  onPress={() => {
                    props.setMedia([recentPhoto]);
                    setRecentPhoto(null);
                    setCameraOpen(false);
                    setMediaOpen(false);
                  }}
                />
              </TouchableOpacity>
            </View>
          )}
        </>
      </Modal>
    </View>
  );
};

export default EventCreateView;
