import React from "react";
import { View } from "react-native-web";
import tw from "twrnc";

import {
  DefaultEvent,
  Gradient1,
  Gradient2,
  Gradient3,
  Gradient4,
  Gradient5,
} from "../../utils/images";

interface HeaderImageProps {
  image?: string | null;
  gradientIndex?: number;
}

const HeaderImage: React.FC<HeaderImageProps> = ({
  image = null,
  gradientIndex = 0,
}) => {
  const gradients = [Gradient1, Gradient2, Gradient3, Gradient4, Gradient5];
  const gradient = gradientIndex < gradients.length ? gradients[gradientIndex] : DefaultEvent;
  
  return (
    <View style={tw`w-full flex justify-center items-center rounded-[20px] shadow-lg`}>
      <img 
        src={image ? image : gradient} 
        style={tw`w-full aspect-square rounded-[20px]`}
      />
    </View>
  );
};

export default React.memo(HeaderImage);
