import { useEffect, useState } from 'react';

export function useScreen() {
    const [width, setWidth] = useState(0);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const handleResize = () => {
                const newWidth = window.innerWidth;
                setWidth(newWidth);
                setIsMobile(newWidth < 768);
            };

            // Set initial values
            handleResize();

            window.addEventListener('resize', handleResize, { passive: true });

            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);

    return { width, isMobile };
}
