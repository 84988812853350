import { StyleSheet } from "react-native";
import { color, snow, ash } from "../../utils/color";

export default StyleSheet.create({
    container: {
        justifyContent: "center",
        alignItems: "center",

        marginBottom: 12,
    },

    image: {
        width: "80vmin",
        height: "80vmin",
        borderRadius: 10,
        marginBottom: 24,
        backgroundColor: snow,
        justifyContent: "center",
        alignItems: "center",
    },

    icon: {
        tintColor: ash,
        width: 60,
        height: 60,
    },

    iconContainer: {
        alignItems: "center",
    },

    text: { color: snow },
});
